import React, { PureComponent } from "react";
import _ from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import { createMediasApi } from "../../services/medias";
import { withRedux } from "../../hoc";

const mapStateToProps = state => {
  return {
    t: _.get(state, "t"),
    languages: _.get(state, "languages"),
    auth: _.get(state, "auth.data"),
  };
};

const actionToProps = {};

class TextEditor extends PureComponent {
  render() {
    const { value, onChange } = this.props;
    const props = {
      apiKey: "j0oqi7pz37eyxvmjrouumn4gveac49fdb4anhn57g4vtf05s",
      init: {
        height: 600,
        menubar: false,
        plugins:
          "advlist autolink link image lists charmap print hr anchor pagebreak spellchecker searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking tinydrive imagetools save table contextmenu directionality emoticons template paste textcolor",
        toolbar:
          "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | link image | forecolor backcolor emoticons | showcomments | code",
        // tinycomments_mode: "embedded",
        // tinycomments_author: _.get(auth, "username"),
        // tinydrive_token_provider: `${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/tinymce/jwt`,
        images_upload_url: `${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/medias`,
        images_upload_handler: async (blobInfo, success, failure) => {
          const uploadResult = await createMediasApi(blobInfo.blob());
          if (_.get(uploadResult, "status") === "success") {
            success(_.get(uploadResult, "data.fullpath"));
          } else {
            failure(_.get(uploadResult, "error_message", "Upload failed."));
          }
        },
      },
      onEditorChange: (content, editor) => {
        onChange(content);
      },
    };
    if (value) props.initialValue = value;
    return <Editor {...props} />;
  }
}

export default withRedux(mapStateToProps, actionToProps)(TextEditor);
