import React, { PureComponent } from "react";
import styled from "styled-components";

import noImageAvailable from "../../assets/images/noimage.jpg";

export default class BackgroundImage extends PureComponent {
  static defaultProps = {
    className: "",
    image: noImageAvailable,
    size: "cover",
    repeat: "no-repeat",
    position: "center"
  };
  render() {
    const { className, image, children } = this.props;
    return (
      <Styled
        {...this.props}
        className={`background-image-component ${className}`}
        image={image || noImageAvailable}
      >
        {children}
      </Styled>
    );
  }
}

const Styled = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.image}');
  background-size: ${props => props.size};
  background-repeat: ${props => props.repeat};
  background-position: ${props => props.position};
`;
