import React, { PureComponent } from "react";

import noImageAvailable from "../../assets/images/noimage.jpg";

export default class Image extends PureComponent {
  static defaultProps = {
    className: "",
    src: noImageAvailable,
    alt: process.env.REACT_APP_WEBSITE_NAME
  };
  static getDerivedStateFromProps(props, state) {
    if (props.src !== state.prevSrc) {
      return {
        prevSrc: props.src || noImageAvailable,
        src: props.src || noImageAvailable
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = {
      prevSrc: props.src,
      src: props.src
    };
  }
  handleError = () => {
    this.setState({ src: noImageAvailable });
  };
  render() {
    const { src } = this.state;
    const { className, alt } = this.props;
    return (
      <img
        {...this.props}
        className={`image-component ${className}`}
        src={src}
        alt={alt}
        onError={this.handleError}
      />
    );
  }
}
