import _ from "lodash";
import { fetchApi } from "../utils";

const getMediasApi = () => {
  return fetchApi("medias").then(res => _.get(res, "result"));
};

const getMediasByIdApi = id => {
  return fetchApi(`medias/${id}`);
};

const createMediasApi = file => {
  const data = new FormData();
  data.append("file", file);
  return fetchApi("medias", "POST", data);
};

const updateMediasApi = (id, data) => {
  return fetchApi(`medias/${id}`, "PUT", JSON.stringify(data));
};

const deleteMediasApi = id => {
  return fetchApi(`medias/${id}`, "DELETE");
};

export {
  getMediasApi,
  getMediasByIdApi,
  createMediasApi,
  updateMediasApi,
  deleteMediasApi,
};
