import * as ActionType from "../actions/actionType";

const initialState = {
  data: {},
  active: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SWITCH_LANGUAGE:
      return {
        ...state,
        active: action.language
      };
    case ActionType.RECEIVE_LANGUAGES:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
};
