import _ from "lodash";
import { fetchApi } from "../utils";

const getLanguagesApi = () => {
  return fetchApi("languages").then(res => _.get(res, "result"));
};

const getLanguagesByIdApi = id => {
  return fetchApi(`languages/${id}`);
};

const createLanguagesApi = data => {
  return fetchApi("languages", "POST", JSON.stringify(data));
};

const updateLanguagesApi = (id, data) => {
  return fetchApi(`languages/${id}`, "PUT", JSON.stringify(data));
};

const deleteLanguagesApi = id => {
  return fetchApi(`languages/${id}`, "DELETE");
};

export {
  getLanguagesApi,
  getLanguagesByIdApi,
  createLanguagesApi,
  updateLanguagesApi,
  deleteLanguagesApi,
};
