import React, { PureComponent } from "react";

import DraftWysiwyg from "./draft-wysiwyg";
import Tinymce from "./tinymce";
import CKEditor from "./ckeditor";

export default class TextEditor extends PureComponent {
  static defaultProps = {
    driver: "ckeditor",
  };
  render() {
    const { driver } = this.props;
    if (driver === "draft-wysiwyg") return <DraftWysiwyg {...this.props} />;
    if (driver === "tinymce") return <Tinymce {...this.props} />;
    if (driver === "ckeditor") return <CKEditor {...this.props} />;
  }
}
