import React, { PureComponent } from "react";
import { Tree, Icon, Popconfirm, Tooltip, Empty } from "antd";
import _ from "lodash";

const TreeNode = Tree.TreeNode;

export default class DraggableTree extends PureComponent {
  static defaultProps = {
    onChange: () => null
  };

  state = {
    prevData: [],
    data: []
  };

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.data, state.prevData)) {
      return {
        prevData: props.data,
        data: props.data
      };
    }
    return null;
  }

  onDragEnter = info => {
    // console.log("onDragEnter", info);
    // expandedKeys 需要受控时设置
    // this.setState({
    //   expandedKeys: info.expandedKeys
    // });
  };

  onDrop = info => {
    // console.log("onDrop", info);
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (_.toString(item.key) === _.toString(key)) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };
    const data = [...this.state.data];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.setState({
      data
    });

    this.props.onChange(data);
  };

  render() {
    const { onEdit, onDelete } = this.props;
    const loop = data =>
      data.map(item => {
        const { key, title, children } = item;
        const titleHtml = (
          <ul className="actions-btn">
            <li>{title}</li>
            {onEdit && (
              <li>
                <Tooltip title="Edit">
                  <Icon type="edit" onClick={() => this.props.onEdit(key)} style={{ cursor: "pointer" }} />
                </Tooltip>
              </li>
            )}
            {onDelete && (
              <li>
                <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={() => this.props.onDelete(key)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete">
                    <Icon type="delete" style={{ cursor: "pointer" }} />
                  </Tooltip>
                </Popconfirm>
              </li>
            )}
          </ul>
        );
        const propsTreeNode = {
          key,
          title: titleHtml,
          selectable: false
        };
        if (children && children.length) {
          return <TreeNode {...propsTreeNode}>{loop(children)}</TreeNode>;
        }
        return <TreeNode {...propsTreeNode} />;
      });
    if (_.size(this.state.data) === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <Tree
        {...this.props}
        className="draggable-tree"
        draggable={onEdit ? true : false}
        defaultExpandAll
        onDragEnter={this.onDragEnter}
        onDrop={this.onDrop}
      >
        {loop(this.state.data)}
      </Tree>
    );
  }
}
