import React, { Component } from "react";
import { Typography } from "antd";
// import _ from "lodash";

import "./index.less";

const { Title } = Typography;

export default class Dashboard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    // const { title } = this.props;
    return (
      <div className="dashboard-page">
        {/* <div className="page-header-container">
          <div className="page-header">
            <PageHeader title={title} extra={null}></PageHeader>
          </div>
        </div> */}
        <div className="main-content-container">
          <div className="main-content">
            <Title>Welcome to {process.env.REACT_APP_WEBSITE_NAME}</Title>
          </div>
        </div>
      </div>
    );
  }
}
