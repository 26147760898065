import _ from "lodash";
import { getAuthByToken } from "./auth";
import { dispatchLanguages } from "./languages";
import { dispatchMedias } from "./medias";
import { dispatchSettings } from "./settings";

const loadResource = async dispatch => {
  const listApi = [];
  listApi.push(dispatchLanguages(dispatch));
  listApi.push(dispatchMedias(dispatch));
  listApi.push(dispatchSettings(dispatch));
  await Promise.all(listApi);
};

const initApp = () => {
  return async dispatch => {
    try {
      getAuthByToken(dispatch).then(async user => {
        if (_.get(user, "id")) {
          await loadResource(dispatch);
        }
      });
    } catch (err) {
      console.error("initApp err", err);
    }
  };
};

export { initApp, loadResource };
