import React, { PureComponent } from "react";
import { Form } from "antd";
import _ from "lodash";
import { MediaPopup } from "../../components";
import modifyVars from "../../config/modifyVars";
import { withRedux } from "../../hoc";
import { fetchApi } from "../../utils";

const mapStateToProps = state => {
  return {
    t: _.get(state, "t"),
    languages: _.get(state, "languages"),
  };
};

const actionToProps = {};

class PackageImages extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rooftypes: [],
      packages: [],
      prevRooftypePackages: props.value,
      rooftypePackages: props.value,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.value, state.prevRooftypePackages)) {
      return {
        prevRooftypePackages: props.value,
        rooftypePackages: props.value,
      };
    }

    return null;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const [resultRooftypes, resultPackages] = await Promise.all([
      fetchApi("roof_types"),
      fetchApi("packages"),
    ]);

    this.setState({
      rooftypes: _.get(resultRooftypes, "result"),
      packages: _.get(resultPackages, "result"),
    });
  };

  render() {
    const { t } = this.props;
    const { rooftypes, packages, rooftypePackages } = this.state;
    return (
      <>
        {_.map(rooftypes, (rooftype, rooftypeIndex) => {
          const rooftypeId = rooftype?.id;
          const rooftypeName = t(rooftype?.name);
          return (
            <div key={rooftypeId}>
              <h2
                style={{
                  color: "#ffffff",
                  backgroundColor: modifyVars["@primary-color"],
                  padding: "5px 10px",
                  marginBottom: 10,
                }}
              >
                {rooftypeName}
              </h2>
              <div>
                {_.map(packages, (_package, packageIndex) => {
                  const nextRooftypePackages = [...(rooftypePackages || [])];
                  const nextRooftypePackageIndex = _.findIndex(
                    nextRooftypePackages,
                    { id: rooftypeId }
                  );

                  const packageId = _package?.id;
                  const packageName = _package?.system;
                  const packageImage = _.get(
                    nextRooftypePackages,
                    `${nextRooftypePackageIndex}.images.${packageIndex}.images`,
                    []
                  );

                  _.set(
                    nextRooftypePackages,
                    `${nextRooftypePackageIndex}.images.${packageIndex}.id`,
                    packageId
                  );

                  return (
                    <Form.Item key={packageIndex} label={packageName}>
                      <MediaPopup
                        value={packageImage}
                        multiple
                        label="Image"
                        onChange={values => {
                          _.set(
                            nextRooftypePackages,
                            `${nextRooftypePackageIndex}.images.${packageIndex}.images`,
                            values
                          );
                          this.setState({
                            rooftypePackages: nextRooftypePackages,
                          });
                          this.props.onChange(nextRooftypePackages);
                        }}
                      />
                    </Form.Item>
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(PackageImages);
