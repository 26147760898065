import React, { PureComponent } from "react";
import { Button, Icon, Form } from "antd";
import _ from "lodash";
import styled from "styled-components";
import { InputToFormItem } from "../../components";

const RepeatComponentStyle = styled.div`
  flex: 1 1 50%;
  .row-value {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #d9d9d9;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    .ant-form-item {
      flex: 1 1 50%;
      padding: 0 8px;
    }
  }
  .add-block {
    text-align: right;
  }
  .remove-block {
    position: absolute;
    top: 5px;
    right: 5px;
    i {
      cursor: pointer;
      font-size: 16px;
    }
  }
`;

const defaultRows = [0];

export default class Repeat extends PureComponent {
  state = {
    rows: defaultRows,
    prevData: null
  };
  static getDerivedStateFromProps(props, state) {
    const currentData = _.get(props, "defaultValue");
    if (!_.isEqual(currentData, state.prevData)) {
      let rows = defaultRows;
      if (!_.isEmpty(currentData)) rows = _.map(currentData, (v, i) => i);
      return {
        rows,
        prevData: currentData
      };
    }
    return null;
  }
  handleAddRow = () => {
    const { rows } = this.state;
    const nextKeys = rows.concat(_.last(rows) + 1);
    this.setState({ rows: nextKeys });
  };
  handleRemoveRow = key => {
    const { rows } = this.state;
    if (rows.length === 1) {
      return;
    }
    const nextKeys = _.filter(rows, k => k !== key);
    this.setState({ rows: nextKeys });
  };
  setFieldNameFormItems = index => {
    const { formItems, defaultValue } = this.props;
    return _.map(formItems, formItem => {
      const { field } = formItem;
      return {
        ...formItem,
        field: `${this.props.field}[${index}]${field}`,
        defaultValue: _.get(defaultValue, `${index}.${field}`)
      };
    });
  };
  render() {
    const { rows } = this.state;
    const { label } = this.props;
    return (
      <RepeatComponentStyle className="repeat-component">
        <Form.Item label={label}>
          <div className="value-block">
            {_.map(rows, (key, index) => {
              const formItems = this.setFieldNameFormItems(key);
              return (
                <div className={`row-value row-${index}`} key={key}>
                  <InputToFormItem {...this.props} formItems={formItems} />
                  {rows.length > 1 && (
                    <div className="remove-block">
                      <Icon type="close" theme="outlined" onClick={() => this.handleRemoveRow(key)} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="add-block">
            <Button type="primary" onClick={this.handleAddRow}>
              Add Row
            </Button>
          </div>
        </Form.Item>
      </RepeatComponentStyle>
    );
  }
}
