export default [
  // {
  //   key: "dashboard",
  //   title: "Dashboard",
  //   link: "/",
  //   icon: "dashboard",
  //   permissions: ["dashboard.view"],
  // },
  {
    key: "page",
    title: "Page",
    link: "/page/home",
    icon: "home",
    permissions: [
      "page.home.view",
      "page.home.save",
      "page.about.view",
      "page.about.save",
      "page.why_solar_energy.view",
      "page.why_solar_energy.save",
      "page.news.view",
      "page.news.save",
      "page.customer_area.view",
      "page.customer_area.save",
      "page.residential.view",
      "page.residential.save",
      "page.commercial.view",
      "page.commercial.save",
      "page.project.view",
      "page.project.save",
      "page.config.view",
      "page.config.save",
      "page.learn.view",
      "page.learn.save",
      "page.powerwall.view",
      "page.powerwall.save",
      "page.ev_charger.view",
      "page.ev_charger.save",
      "page.blog.view",
      "page.blog.save",
    ],
    children: [
      {
        key: "page-home",
        title: "Home",
        link: "/page/home",
        icon: null,
        permissions: ["page.home.view", "page.home.save"],
      },
      {
        key: "page-about",
        title: "About",
        link: "/page/about",
        icon: null,
        permissions: ["page.about.view", "page.about.save"],
      },
      {
        key: "page-why_solar_energy",
        title: "Why Solar Energy",
        link: "/page/why_solar_energy",
        icon: null,
        permissions: [
          "page.why_solar_energy.view",
          "page.why_solar_energy.save",
        ],
      },
      {
        key: "page-news",
        title: "News",
        link: "/page/news",
        icon: null,
        permissions: ["page.news.view", "page.news.save"],
      },
      {
        key: "page-customer_area",
        title: "Customer Area",
        link: "/page/customer_area",
        icon: null,
        permissions: ["page.customer_area.view", "page.customer_area.save"],
      },
      {
        key: "page-residential",
        title: "Residential",
        link: "/page/residential",
        icon: null,
        permissions: ["page.residential.view", "page.residential.save"],
      },
      {
        key: "page-commercial",
        title: "Commercial",
        link: "/page/commercial",
        icon: null,
        permissions: ["page.commercial.view", "page.commercial.save"],
      },
      {
        key: "page-project",
        title: "Project",
        link: "/page/project",
        icon: null,
        permissions: ["page.project.view", "page.project.save"],
      },
      {
        key: "page-config",
        title: "Config",
        link: "/page/config",
        icon: null,
        permissions: ["page.config.view", "page.config.save"],
      },
      {
        key: "page-learn",
        title: "Learn Hub",
        link: "/page/learn",
        icon: null,
        permissions: ["page.learn.view", "page.learn.save"],
      },
      {
        key: "page-powerwall",
        title: "Powerwall",
        link: "/page/powerwall",
        icon: null,
        permissions: ["page.powerwall.view", "page.powerwall.save"],
      },
      {
        key: "page-ev_charger",
        title: "EV Charger",
        link: "/page/ev_charger",
        icon: null,
        permissions: ["page.ev_charger.view", "page.ev_charger.save"],
      },
      {
        key: "page-blog",
        title: "Blog",
        link: "/page/blog",
        icon: null,
        permissions: ["page.blog.view", "page.blog.save"],
      },
    ],
  },
  {
    key: "post",
    title: "Post",
    link: "/post/posts",
    icon: "pushpin",
    permissions: [
      "post.posts.view",
      "post.posts.add",
      "post.posts.edit",
      "post.posts.delete",
      "post.categories.view",
      "post.categories.add",
      "post.categories.edit",
      "post.categories.delete",
      "post.tags.view",
      "post.tags.add",
      "post.tags.edit",
      "post.tags.delete",
    ],
    children: [
      {
        key: "post-posts",
        title: "Posts",
        link: "/post/posts",
        icon: null,
        permissions: [
          "post.posts.view",
          "post.posts.add",
          "post.posts.edit",
          "post.posts.delete",
        ],
      },
      {
        key: "post-categories",
        title: "Categories",
        link: "/post/categories",
        icon: null,
        permissions: [
          "post.categories.view",
          "post.categories.add",
          "post.categories.edit",
          "post.categories.delete",
        ],
      },
      // {
      //   key: "post-tags",
      //   title: "Tags",
      //   link: "/post/tags",
      //   icon: null,
      //   permissions: [
      //     "post.tags.view",
      //     "post.tags.add",
      //     "post.tags.edit",
      //     "post.tags.delete",
      //   ],
      // },
    ],
  },
  {
    key: "faq",
    title: "Faq",
    link: "/faq/faqs",
    icon: "question-circle",
    permissions: [
      "faq.faqs.view",
      "faq.faqs.add",
      "faq.faqs.edit",
      "faq.faqs.delete",
      "faq.categories.view",
      "faq.categories.add",
      "faq.categories.edit",
      "faq.categories.delete",
    ],
    children: [
      {
        key: "faq-faqs",
        title: "Faqs",
        link: "/faq/faqs",
        icon: null,
        permissions: [
          "faq.faqs.view",
          "faq.faqs.add",
          "faq.faqs.edit",
          "faq.faqs.delete",
        ],
      },
      {
        key: "faq-categories",
        title: "Categories",
        link: "/faq/categories",
        icon: null,
        permissions: [
          "faq.categories.view",
          "faq.categories.add",
          "faq.categories.edit",
          "faq.categories.delete",
        ],
      },
    ],
  },
  {
    key: "learns",
    title: "Learn Hub",
    link: "/learns",
    icon: "book",
    permissions: ["learns.view", "learns.add", "learns.edit", "learns.delete"],
  },
  {
    key: "medias",
    title: "Media Library",
    link: "/medias",
    icon: "file-image",
    permissions: ["medias.view", "medias.add", "medias.edit", "medias.delete"],
  },
  // {
  //   key: "project_categories",
  //   title: "Project Categories",
  //   link: "/project_categories",
  //   icon: "appstore",
  //   permissions: [
  //     "project_categories.view",
  //     "project_categories.add",
  //     "project_categories.edit",
  //     "project_categories.delete",
  //   ],
  // },
  {
    key: "projects",
    title: "Projects",
    link: "/projects",
    icon: "project",
    permissions: [
      "projects.view",
      "projects.add",
      "projects.edit",
      "projects.delete",
    ],
  },
  {
    key: "partners",
    title: "Partners",
    link: "/partners",
    icon: "deployment-unit",
    permissions: ["partners.view", "partners.save"],
  },
  {
    key: "google_sheets",
    title: "Google Sheets",
    link: "/google_sheets",
    icon: "google",
    permissions: [
      "google_sheets.view",
      "google_sheets.add",
      "google_sheets.edit",
      "google_sheets.delete",
    ],
  },
  {
    key: "solar",
    title: "Solar",
    link: "/solar/packages",
    icon: "bulb",
    permissions: [
      "solar.packages.view",
      "solar.packages.add",
      "solar.packages.edit",
      "solar.packages.delete",
      "solar.roof_types.view",
      "solar.roof_types.add",
      "solar.roof_types.edit",
      "solar.roof_types.delete",
      "solar.meter_types.view",
      "solar.meter_types.add",
      "solar.meter_types.edit",
      "solar.meter_types.delete",
      "solar.power_periods.view",
      "solar.power_periods.add",
      "solar.power_periods.edit",
      "solar.power_periods.delete",
    ],
    children: [
      {
        key: "solar-packages",
        title: "Packages",
        link: "/solar/packages",
        icon: null,
        permissions: [
          "solar.packages.view",
          "solar.packages.add",
          "solar.packages.edit",
          "solar.packages.delete",
        ],
      },
      {
        key: "solar-roof_types",
        title: "Roof Types",
        link: "/solar/roof_types",
        icon: null,
        permissions: [
          "solar.roof_types.view",
          "solar.roof_types.add",
          "solar.roof_types.edit",
          "solar.roof_types.delete",
        ],
      },
      {
        key: "solar-meter_types",
        title: "Meter Types",
        link: "/solar/meter_types",
        icon: null,
        permissions: [
          "solar.meter_types.view",
          "solar.meter_types.add",
          "solar.meter_types.edit",
          "solar.meter_types.delete",
        ],
      },
      {
        key: "solar-power_periods",
        title: "Power Periods",
        link: "/solar/power_periods",
        icon: null,
        permissions: [
          "solar.power_periods.view",
          "solar.power_periods.add",
          "solar.power_periods.edit",
          "solar.power_periods.delete",
        ],
      },
    ],
  },
  {
    key: "setting",
    title: "Setting",
    link: "/setting/contact",
    icon: "setting",
    permissions: [
      "setting.contact.view",
      "setting.contact.save",
      "setting.email.view",
      "setting.email.save",
    ],
    children: [
      {
        key: "setting-contact",
        title: "Contact",
        link: "/setting/contact",
        icon: null,
        permissions: ["setting.contact.view", "setting.contact.save"],
      },
      {
        key: "setting-email",
        title: "Email Payment",
        link: "/setting/email",
        icon: null,
        permissions: ["setting.email.view", "setting.email.save"],
      },
      {
        key: "setting-coupon",
        title: "Coupon Code",
        link: "/setting/coupon",
        icon: null,
        permissions: ["setting.coupon.view", "setting.coupon.save"],
      },
      {
        key: "setting-powerwall",
        title: "Powerwall Landing",
        link: "/setting/powerwall",
        icon: null,
        permissions: ["setting.powerwall.view", "setting.powerwall.save"],
      },
    ],
  },
];
