import React from "react";
import { Redirect } from "react-router-dom";
import { Dashboard, Layout, Setting, Media, Profile } from "../pages";
import resources from "./resources";

export default [
  {
    exact: true,
    path: "/",
    component: Dashboard,
    privateRoute: true,
  },
  {
    exact: true,
    path: "/profile",
    component: Profile,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: [],
    title: "Profile",
  },
  {
    exact: true,
    path: "/medias",
    component: Media,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["medias"],
    title: "Media Library",
    ...resources.medias,
  },
  {
    exact: true,
    path: "/setting/languages",
    component: Setting.Language,
    privateRoute: true,
    subMenuActiveKey: ["setting"],
    menuActiveKey: ["setting-languages"],
    title: "Languages",
    ...resources.languages,
  },
  {
    exact: true,
    path: "/page/home",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-home"],
    title: "Page Home",
    ...resources["page/home"],
  },
  {
    exact: true,
    path: "/page/about",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-about"],
    title: "Page About",
    ...resources["page/about"],
  },
  {
    exact: true,
    path: "/page/why_solar_energy",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-why_solar_energy"],
    title: "Page Why Solar Energy",
    ...resources["page/why_solar_energy"],
  },
  {
    exact: true,
    path: "/page/news",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-news"],
    title: "Page News",
    ...resources["page/news"],
  },
  {
    exact: true,
    path: "/page/customer_area",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-customer_area"],
    title: "Page Customer Area",
    ...resources["page/customer_area"],
  },
  {
    exact: true,
    path: "/page/residential",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-residential"],
    title: "Page Residential",
    ...resources["page/residential"],
  },
  {
    exact: true,
    path: "/page/commercial",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-commercial"],
    title: "Page Commercial",
    ...resources["page/commercial"],
  },
  {
    exact: true,
    path: "/page/project",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-project"],
    title: "Page Project",
    ...resources["page/project"],
  },
  {
    exact: true,
    path: "/page/config",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-config"],
    title: "Config",
    ...resources["page/config"],
  },
  {
    exact: true,
    path: "/page/learn",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-learn"],
    title: "Page Learn Hub",
    ...resources["page/learn"],
  },
  {
    exact: true,
    path: "/page/powerwall",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-powerwall"],
    title: "Page Powerwall",
    ...resources["page/powerwall"],
  },
  {
    exact: true,
    path: "/page/ev_charger",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-ev_charger"],
    title: "EV Charger",
    ...resources["page/ev_charger"],
  },
  {
    exact: true,
    path: "/page/blog",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["page"],
    menuActiveKey: ["page-blog"],
    title: "Blog",
    ...resources["page/blog"],
  },
  {
    exact: true,
    path: "/post/categories",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-categories"],
    title: "Post Categories",
    ...resources.post_categories,
  },
  {
    exact: true,
    path: "/post/categories/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-categories"],
    title: "Post Category",
    ...resources.post_categories,
    redirectPath: "/post/categories",
  },
  {
    exact: true,
    path: "/post/tags",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-tags"],
    title: "Post Tags",
    ...resources.post_tags,
  },
  {
    exact: true,
    path: "/post/tags/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-tags"],
    title: "Post Tag",
    ...resources.post_tags,
    redirectPath: "/post/tags",
  },
  {
    exact: true,
    path: "/post/posts",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-posts"],
    title: "Posts",
    ...resources.posts,
  },
  {
    exact: true,
    path: "/post/posts/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["post"],
    menuActiveKey: ["post-posts"],
    title: "Post",
    ...resources.posts,
    redirectPath: "/post/posts",
  },
  {
    exact: true,
    path: "/faq/categories",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["faq"],
    menuActiveKey: ["faq-categories"],
    title: "Faq Categories",
    ...resources.faq_categories,
  },
  {
    exact: true,
    path: "/faq/categories/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["faq"],
    menuActiveKey: ["faq-categories"],
    title: "Faq Category",
    ...resources.faq_categories,
    redirectPath: "/faq/categories",
  },
  {
    exact: true,
    path: "/faq/faqs",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["faq"],
    menuActiveKey: ["faq-faqs"],
    title: "Faqs",
    ...resources.faqs,
  },
  {
    exact: true,
    path: "/faq/faqs/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["faq"],
    menuActiveKey: ["faq-faqs"],
    title: "Faq",
    ...resources.faqs,
    redirectPath: "/faq/faqs",
  },
  {
    exact: true,
    path: "/learns",
    component: Layout.Categories.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["learns"],
    title: "Learn Hub",
    ...resources.learn_categories,
  },
  {
    exact: true,
    path: "/learns/save/:id?",
    component: Layout.Categories.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["learns"],
    title: "Learn Hub",
    ...resources.learn_categories,
    redirectPath: "/learns",
  },
  {
    exact: true,
    path: "/project_categories",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["project_categories"],
    title: "Project Categories",
    ...resources.project_categories,
  },
  {
    exact: true,
    path: "/project_categories/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["project_categories"],
    title: "Project Category",
    ...resources.project_categories,
    redirectPath: "/project_categories",
  },
  {
    exact: true,
    path: "/projects",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["projects"],
    title: "Projects",
    ...resources.projects,
  },
  {
    exact: true,
    path: "/projects/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["projects"],
    title: "Project",
    ...resources.projects,
    redirectPath: "/projects",
  },
  {
    exact: true,
    path: "/partners",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["partners"],
    title: "Partners",
    ...resources["page/partners"],
  },
  {
    exact: true,
    path: "/google_sheets",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["google_sheets"],
    title: "Google Sheets",
    ...resources.google_sheets,
  },
  {
    exact: true,
    path: "/google_sheets/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: [],
    menuActiveKey: ["google_sheets"],
    title: "Google Sheet",
    ...resources.google_sheets,
    redirectPath: "/google_sheets",
  },
  {
    exact: true,
    path: "/solar/packages",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-packages"],
    title: "Packages",
    ...resources.packages,
  },
  {
    exact: true,
    path: "/solar/packages/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-packages"],
    title: "Package",
    ...resources.packages,
    redirectPath: "/solar/packages",
  },
  {
    exact: true,
    path: "/solar/roof_types",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-roof_types"],
    title: "Roof Types",
    ...resources.roof_types,
  },
  {
    exact: true,
    path: "/solar/roof_types/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-roof_types"],
    title: "Roof Type",
    ...resources.roof_types,
    redirectPath: "/solar/roof_types",
  },
  {
    exact: true,
    path: "/solar/meter_types",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-meter_types"],
    title: "Meter Types",
    ...resources.meter_types,
  },
  {
    exact: true,
    path: "/solar/meter_types/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-meter_types"],
    title: "Meter Type",
    ...resources.meter_types,
    redirectPath: "/solar/meter_types",
  },
  {
    exact: true,
    path: "/solar/power_periods",
    component: Layout.List,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-power_periods"],
    title: "Power Periods",
    ...resources.power_periods,
  },
  {
    exact: true,
    path: "/solar/power_periods/save/:id?",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["solar"],
    menuActiveKey: ["solar-power_periods"],
    title: "Power Period",
    ...resources.power_periods,
    redirectPath: "/solar/power_periods",
  },
  {
    exact: true,
    path: "/setting/contact",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["setting"],
    menuActiveKey: ["setting-contact"],
    title: "Setting Contact",
    ...resources["page/setting_contact"],
  },
  {
    exact: true,
    path: "/setting/email",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["setting"],
    menuActiveKey: ["setting-email"],
    title: "Setting Email Payment",
    ...resources["page/setting_email"],
  },
  {
    exact: true,
    path: "/setting/coupon",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["setting"],
    menuActiveKey: ["setting-coupon"],
    title: "Setting Coupon Code",
    ...resources["page/setting_coupon"],
  },
  {
    exact: true,
    path: "/setting/powerwall",
    component: Layout.Save,
    privateRoute: true,
    subMenuActiveKey: ["setting"],
    menuActiveKey: ["setting-powerwall"],
    title: "Powerwall Landing",
    ...resources["page/setting_powerwall"],
  },
  {
    component: () => <Redirect to={{ pathname: "/" }} />,
  },
];
