import _ from "lodash";
import permissions from "../config/permissions";

export const fetchJson = (url, options = {}) => {
  return fetch(url, options)
    .then(response => response.json())
    .catch(error => error);
};

export const fetchApi = (url, method = "GET", data = {}, headers = {}) => {
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      ...headers,
    },
  };
  if (method === "POST" || method === "PUT") {
    if (headers !== false) {
      options.headers = {
        "Content-Type": "application/json",
        ...options.headers,
      };
    }
    options.body = data;
  }
  return fetchJson(
    `${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/${url}`,
    options
  );
};

export const toObject = items => {
  return _.keyBy(items, "id");
};

export const sortCollection = items => {
  return _.orderBy(items, ["created_at"], ["desc"]);
};

export const stripHtml = html => {
  if (_.isBoolean(html)) return html;
  if (!html) return html;
  return _.replace(html, /(<([^>]+)>)/gi, "");
};

export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel = true) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData =
    typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  let CSV = "";
  //Set Report title in first row or line

  // CSV += ReportTitle + "\r\n\n";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = "";

    //This loop will extract the label from 1st index of on array
    for (const index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ",";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[i]) {
      row += '"' + _.get(arrData, `${i}.${index}`, "") + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  //this will remove the blank-spaces from the title and replace it with an underscore
  const fileName = ReportTitle.replace(/ /g, "_");

  const universalBOM = "\uFEFF";

  //Initialize file format you want csv or xls
  const uri =
    "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  const link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getOnlyKeyPermissions = () => {
  const result = [];
  const recursive = permissions => {
    _.map(permissions, permission => {
      result.push(permission.key);
      if (permission.children) recursive(permission.children);
    });
    return result;
  };
  recursive(permissions);
  return result;
};

export const authHasPermission = (
  authPermissions,
  permissions = getOnlyKeyPermissions()
) => {
  if (!_.includes(permissions, "all")) permissions.push("all");
  if (_.isEmpty(authPermissions)) return false;
  const hasPermission = _.some(authPermissions, authPermission => {
    return _.includes(permissions, authPermission.slug);
  });
  return hasPermission;
};

export const convertSelectedFiles = value => {
  return _.isArray(value)
    ? _.isObject(_.get(value, "0"))
      ? _.map(value, v => _.get(v, "id"))
      : value
    : value;
};

export const convertDataToSubmit = ({ values, formItems, languages }) => {
  const newValues = _.clone(values);

  for (let i = 0; i < formItems.length; i++) {
    const formItem = formItems[i];
    if (formItem.inputType === "repeat") {
      for (let j = 0; j < formItem.formItems.length; j++) {
        const formItem2 = formItem.formItems[j];
        if (formItem2.inputType === "repeat") {
          for (let l = 0; l < formItem2.formItems.length; l++) {
            const formItem3 = formItem2.formItems[l];
            if (formItem3.inputType === "repeat") {
            } else {
              if (formItem3.multiLanguage) {
                const oldDatas = formItem.defaultValue;
                const currentDatas = _.get(newValues, `${formItem.field}`);
                if (!_.isEmpty(currentDatas)) {
                  for (let m = 0; m < currentDatas.length; m++) {
                    const currentData = currentDatas[m];
                    const currentDatas2 = _.get(currentData, formItem2.field);
                    if (!_.isEmpty(currentDatas2)) {
                      for (let n = 0; n < currentDatas2.length; n++) {
                        const currentData2 = currentDatas2[n];
                        const currentDatas3 = _.get(
                          currentData2,
                          formItem3.field
                        );
                        if (!_.isEmpty(currentDatas3)) {
                          _.set(
                            newValues,
                            `${formItem.field}.${m}.${formItem2.field}.${n}.${formItem3.field}`,
                            {
                              ..._.get(
                                oldDatas,
                                `${m}.${formItem2.field}.${n}.${formItem3.field}`
                              ),
                              [languages.active]: _.get(
                                newValues,
                                `${formItem.field}.${m}.${formItem2.field}.${n}.${formItem3.field}`
                              ),
                            }
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (formItem2.multiLanguage) {
            const oldDatas = formItem.defaultValue;
            const currentDatas = _.get(newValues, `${formItem.field}`);
            if (!_.isEmpty(currentDatas)) {
              for (let k = 0; k < currentDatas.length; k++) {
                const currentData = currentDatas[k];
                const currentDatas2 = _.get(currentData, formItem2.field);
                if (!_.isEmpty(currentDatas2)) {
                  _.set(
                    newValues,
                    `${formItem.field}.${k}.${formItem2.field}`,
                    {
                      ..._.get(oldDatas, `${k}.${formItem2.field}`),
                      [languages.active]: _.get(
                        newValues,
                        `${formItem.field}.${k}.${formItem2.field}`
                      ),
                    }
                  );
                }
              }
            }
          }
        }
      }
    } else {
      if (formItem.multiLanguage) {
        const oldData = formItem.defaultValue;
        const currentData = _.get(newValues, formItem.field);
        const newData = { ...oldData };
        if (!_.isEmpty(currentData)) {
          newData[languages.active] = currentData;
        }
        _.set(newValues, `${formItem.field}`, newData);
      }
    }
  }

  return newValues;
};

export const translateData = ({ item, formItems, t }) => {
  let newItem = _.clone(item);

  for (let i = 0; i < formItems.length; i++) {
    const formItem = formItems[i];
    if (formItem.inputType === "repeat") {
      for (let j = 0; j < formItem.formItems.length; j++) {
        const formItem2 = formItem.formItems[j];
        if (formItem2.inputType === "repeat") {
          for (let l = 0; l < formItem2.formItems.length; l++) {
            const formItem3 = formItem2.formItems[l];
            if (formItem3.inputType === "repeat") {
            } else {
              if (formItem3.multiLanguage) {
                const currentDatas = _.get(newItem, `${formItem.field}`);
                if (!_.isEmpty(currentDatas)) {
                  for (let m = 0; m < currentDatas.length; m++) {
                    const currentData = currentDatas[m];
                    const currentDatas2 = _.get(currentData, formItem2.field);
                    if (!_.isEmpty(currentDatas2)) {
                      for (let n = 0; n < currentDatas2.length; n++) {
                        const currentData2 = currentDatas2[n];
                        const currentDatas3 = _.get(
                          currentData2,
                          formItem3.field
                        );
                        if (!_.isEmpty(currentDatas3)) {
                          _.set(
                            newItem,
                            `${formItem.field}.${m}.${formItem2.field}.${n}.${formItem3.field}`,
                            t(
                              _.get(
                                newItem,
                                `${formItem.field}.${m}.${formItem2.field}.${n}.${formItem3.field}`
                              )
                            )
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (formItem2.multiLanguage) {
            const currentDatas = _.get(newItem, `${formItem.field}`);
            if (!_.isEmpty(currentDatas)) {
              for (let k = 0; k < currentDatas.length; k++) {
                const currentData = currentDatas[k];
                if (!_.isEmpty(_.get(currentData, formItem2.field))) {
                  _.set(
                    newItem,
                    `${formItem.field}.${k}.${formItem2.field}`,
                    t(
                      _.get(
                        newItem,
                        `${formItem.field}.${k}.${formItem2.field}`
                      )
                    )
                  );
                }
              }
            }
          }
        }
      }
    } else {
      if (formItem.multiLanguage) {
        _.set(
          newItem,
          `${formItem.field}`,
          t(_.get(newItem, `${formItem.field}`))
        );
      }
    }
  }

  return newItem;
};

export const mapCategoriesLevel = d => {
  const findChildren = (d, v) => {
    const options = {
      ...v,
      title: _.get(v, "name"),
      value: _.get(v, "id"),
      key: _.get(v, "id"),
    };
    const parent = _.filter(d, { parent_id: _.get(v, "id") });
    if (!_.isEmpty(parent))
      options.children = _.map(_.sortBy(parent, ["sort"]), p =>
        findChildren(d, p)
      );
    return options;
  };
  const firstLevel = _.filter(d, { parent_id: 0 });
  return _.map(_.sortBy(firstLevel, ["sort"]), v => findChildren(d, v));
};

export const aspectRatioCalculate = ({
  width,
  height,
  originalWidth,
  originalHeight,
}) => {
  let newWidth = originalWidth;
  let newHeight = originalHeight;

  if (width && height) {
    newWidth = width;
    newHeight = height;
  } else if (!width || !height) {
    if (width) {
      newWidth = width;
      newHeight = originalHeight / (originalWidth / width);
    } else if (height) {
      newHeight = height;
      newWidth = originalWidth / (originalHeight / height);
    }
  }
  return {
    newWidth,
    newHeight,
  };
};

export const removeByIndex = (items, index) =>
  items.filter((item, i) => i !== index);

export const removeEmpty = obj => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
