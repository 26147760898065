import React, { PureComponent } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  TreeSelect,
  Switch,
  Checkbox
} from "antd";
import _ from "lodash";
import moment from "moment";
import {
  TextEditor,
  Repeat,
  MediaPopup,
  MyTree,
  InputHidden,
  ColorPicker,
} from "../../components";
import { withRedux } from "../../hoc";
import { convertSelectedFiles } from "../../utils";

const { TextArea } = Input;

const mapStateToProps = state => {
  return {
    t: _.get(state, "t"),
    languages: _.get(state, "languages"),
  };
};

const actionToProps = {};

class InputToFormItem extends PureComponent {
  render() {
    const { formItems, form, t } = this.props;
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        {_.map(formItems, (formItem, key) => {
          const {
            field,
            label,
            extra,
            fieldDecorator = {},
            inputType,
            inputOption = {},
            defaultValue = null,
            multiLanguage,
            component,
          } = formItem;

          let inputComponent = <Input {...inputOption} />;
          let initialValue = _.clone(defaultValue);
          let valuePropName = "value";

          switch (inputType) {
            case "text":
              inputComponent = (
                <Input
                  {...inputOption}
                  onChange={e => {
                    if (inputOption.onChange) {
                      inputOption.onChange({ value: e.target.value, form });
                    }
                  }}
                />
              );
              break;
            case "textarea":
              inputComponent = <TextArea rows={4} {...inputOption} />;
              break;
            case "number":
              inputComponent = <InputNumber {...inputOption} />;
              break;
            case "wysiwyg":
              if (_.get(inputOption, "driver") === "draft-wysiwyg") {
                valuePropName = "initialContentState";
              }
              inputComponent = <TextEditor {...inputOption} />;
              break;
            case "select":
              const { mode } = inputOption;
              if (mode === "tags" || mode === "multiple") {
                initialValue = initialValue ? initialValue : [];
              }
              inputComponent = (
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  {...inputOption}
                  onChange={value => {
                    if (inputOption.onChange) {
                      inputOption.onChange({ value, form });
                    }
                  }}
                />
              );
              break;
            case "tree":
              valuePropName = "defaultCheckedKeys";
              inputComponent = (
                <MyTree checkable defaultExpandAll {...inputOption} />
              );
              break;
            case "tree-select":
              inputComponent = (
                <TreeSelect
                  allowClear
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeDefaultExpandAll
                  {...inputOption}
                />
              );
              break;
            case "switch":
              valuePropName = "checked";
              inputComponent = <Switch {...inputOption} />;
              break;
            case "date-picker":
              if (initialValue) initialValue = moment(initialValue);
              inputComponent = (
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder={null}
                  style={{ width: "100%", minWidth: "initial" }}
                  {...inputOption}
                />
              );
              break;
            case "repeat":
              return <Repeat key={key} {...this.props} {...formItem} />;
            case "media":
              if (initialValue) {
                initialValue = convertSelectedFiles(initialValue);
              }
              if (_.has(formItem, "defaultValue")) {
                delete formItem.defaultValue;
              }
              inputComponent = <MediaPopup {...formItem} {...inputOption} />;
              break;
            case "password":
              inputComponent = <Input.Password {...inputOption} />;
              break;
            case "hidden":
              inputComponent = <InputHidden />;
              break;
            case "color-picker":
              valuePropName = "color";
              inputComponent = <ColorPicker {...inputOption} />;
              break;
            case "custom":
              inputComponent = component;
              break;
            case "checkbox":
              valuePropName = "checked";
              inputComponent = <Checkbox />;
              break;
            default:
              break;
          }

          return (
            <Form.Item key={key} label={label} extra={extra}>
              {getFieldDecorator(field, {
                ...fieldDecorator,
                initialValue: multiLanguage ? t(initialValue) : initialValue,
                valuePropName,
              })(inputComponent)}
            </Form.Item>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(InputToFormItem);
