import React, { Component,useState } from "react";
import _ from "lodash";
import {
  Button,
  Upload,
  Icon,
  message,
  Pagination,
  PageHeader,
  Popconfirm,
  Modal,
  Affix,
  Input,
  Form
} from "antd";
import filesize from "filesize";
import humanizeDuration from "humanize-duration";
import { withRedux } from "../../hoc";
import { BackgroundImage, InputToFormItem } from "../../components";
import { deleteMediasApi, updateMediasApi } from "../../services/medias";
import { getMedias } from "../../actions/medias";
import { authHasPermission } from "../../utils";
import "./style.less";

const mapStateToProps = state => {
  return {
    medias: _.get(state, "medias.data", []),
    auth: _.get(state, "auth.data"),
  };
};

const actionToProps = {
  getMedias,
};

class Media extends Component {
  state = {
    showFileModal: false,
    selectedFile: null,
    selectedFilesTmp: [],
    uploading: false,
    deleting: false,
    currentPage: 1,
    pageSize: 10,
    mode: "default", // default, select
  };

  handleDeselectMedia = id => {
    const { selectedFilesTmp } = this.state;
    let selectedFilesTmpNew = [...selectedFilesTmp];
    if (_.includes(selectedFilesTmp, id)) {
      selectedFilesTmpNew = _.filter(selectedFilesTmp, item => item !== id);
    } else {
      // selectedFilesTmpNew.push(id);
    }
    this.setState({ selectedFilesTmp: selectedFilesTmpNew });
  };

  handleSelectMedia = id => {
    const { mode } = this.state;
    if (mode === "select") {
      const { selectedFilesTmp } = this.state;
      let selectedFilesTmpNew = [...selectedFilesTmp];
      if (_.includes(selectedFilesTmp, id)) {
        selectedFilesTmpNew = _.filter(selectedFilesTmp, item => item !== id);
      } else {
        selectedFilesTmpNew.push(id);
      }
      this.setState({ selectedFilesTmp: selectedFilesTmpNew });
    } else if (mode === "default") {
      this.setState({ showFileModal: true, selectedFile: id });
    }
  };

  renderMediaByType = media => {
    if (!media) return null;
    const { name, fullpath, type } = media;
    if (type === "image") {
      return (
        <div className="type-image">
          <BackgroundImage image={fullpath} size="contain" />
          <div className="file-name">{name}</div>
          
        </div>
      );
    } else if (type === "application" || type === "video") {
      return (
        <div className="type-file">
          <div className="icon-file">
            <Icon type="file" theme="outlined" />
          </div>
          <div className="file-name">{name}</div>
        </div>
      );
    }
    return null;
  };

  renderMediaByTypeOnModal = media => {
    if (!media) return null;
    const { name, fullpath, type } = media;
    if (type === "image") {
      return <img src={fullpath} alt={name} />;
    } else if (type === "video") {
      return <video src={fullpath} alt={name} controls autoPlay />;
    }
    return <Icon type="file" theme="outlined" />;
  };

  handleClickDeleteMedias = async () => {
    const medias = this.getMedias();
    const { selectedFilesTmp, deleting } = this.state;
    if (deleting) return;
    // let selectedFilesTmpNew = [...selectedFilesTmp];
    this.setState({ deleting: true });
    await Promise.all(
      _.map(selectedFilesTmp, async selectedFilesTmpId => {
        const media = _.find(medias, { id: selectedFilesTmpId });
        const mediaName = _.get(media, "name");
        const result = await deleteMediasApi(selectedFilesTmpId);
        if (_.get(result, "status") === "success") {
          // selectedFilesTmpNew = _.filter(
          //   selectedFilesTmp,
          //   item => item !== selectedFilesTmpId
          // );
          message.success(`Delete ${mediaName} success.`);
        } else {
          message.error(`${_.get(result, "error_message")}`);
        }
      })
    );
    this.setState({ deleting: false, selectedFilesTmp: [] });
    this.props.actions.getMedias();
  };

  handleChangePagination = (page, pageSize) => {
    this.setState({ currentPage: page, pageSize });
  };

  handleShowSizeChange = (current, pageSize) => {
    this.setState({ currentPage: current, pageSize });
  };

  getAcceptFile = () => {
    return "image/*, video/*, application/pdf";
  };

  beforeUpload = file => {
    const acceptFile = this.getAcceptFile();
    if (!acceptFile) {
      message.error("You can only upload Image, Video, Pdf file!");
    }
    return true;
  };

  handleUploadChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      this.props.actions.getMedias();
      this.setState({ uploading: false });
    }
  };

  uploadButton = () => {
    const { uploading } = this.state;
    return (
      <div>
        <Icon type={uploading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
  };

  getMedias = () => {
    return this.props.medias;
  };

  renderPageHeader = () => {
    const { selectedFilesTmp, deleting, updating, mode } = this.state;
    const { title, auth, permissionPrefix } = this.props;

    const extra = [];

    if (
      authHasPermission(_.get(auth, "permissions"), [
        `${permissionPrefix}.delete`,
      ]) &&
      mode === "default"
    ) {
      extra.push(
        <Button
          key="select"
          type="primary"
          onClick={() => this.setState({ mode: "select" })}
        >
          Select
        </Button>
      );
    }

    if (mode === "select") {
      extra.push(
        <Button
          key="cancel"
          type="default"
          onClick={() =>
            this.setState({ mode: "default", selectedFilesTmp: [] })
          }
        >
          Cancel
        </Button>
      );
      extra.push(
        // <Link to={`${path}/save/${id}`}>
        //   <Tooltip title="Edit">
        //     <Icon type="edit" />
        //   </Tooltip>
        // </Link>
        <Popconfirm
          key="edit"
          title="Are you sure edit this task?"
          onConfirm={async () => this.handleClickDeleteMedias()}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            loading={updating}
            disabled={_.isEmpty(selectedFilesTmp)}
          >
            Update
          </Button>
        </Popconfirm>
      );

      extra.push(
        <Popconfirm
          key="delete"
          title="Are you sure delete this task?"
          onConfirm={async () => this.handleClickDeleteMedias()}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            loading={deleting}
            disabled={_.isEmpty(selectedFilesTmp)}
          >
            Delete
          </Button>
        </Popconfirm>
      );
    }

    return (
      <div className="page-header">
        <PageHeader title={title} subTitle={null} extra={extra}></PageHeader>
      </div>
    );
  };
  

  renderMainContent = () => {
    const { auth, permissionPrefix } = this.props;
    const {
      showFileModal,
      selectedFile,
      selectedFilesTmp,
      deleting,
      currentPage,
      pageSize,
      updating
    } = this.state;
    
    const medias = this.getMedias();

    const mediaCount = _.size(medias);
    const mediasPagination = _.slice(
      _.toArray(medias),
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const currentMedia = _.find(medias, { id: selectedFile });
    const currentMediaId = _.get(currentMedia, "id");
    const currentMediaName = _.get(currentMedia, "name");
    const currentMediaWidth = _.get(currentMedia, "width");
    const currentMediaHeight = _.get(currentMedia, "height");
    const currentMediaSize = _.get(currentMedia, "size", 0);
    const currentMediaDuration = _.get(currentMedia, "duration");
    const currentMediadataAltTh = _.get(currentMedia, "data.alt.th");
    const currentMediadataAltEn = _.get(currentMedia, "data.alt.en");
    
    return (
      <div className="main-content">
        <div className="media-items-container">
          <div className="media-component media-modal">
            <div className="media-items">
              {authHasPermission(_.get(auth, "permissions"), [
                `${permissionPrefix}.add`,
              ]) && (
                <div className="item item-upload">
                  <Upload
                    accept={this.getAcceptFile()}
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    multiple
                    action={`${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/medias`}
                    headers={{
                      Authorization: `Bearer ${window.localStorage.getItem(
                        "token"
                      )}`,
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleUploadChange}
                  >
                    {this.uploadButton()}
                  </Upload>
                </div>
              )}
              {_.map(mediasPagination, media => {
                const { id } = media;
                const active = _.includes(selectedFilesTmp, id);
                const activeClassName = active ? "active" : "";
                return (
                  <div
                    className={`item ${activeClassName}`}
                    key={id}
                    onClick={() => this.handleSelectMedia(id)}
                  >
                    {active && (
                      <div className="check-block">
                        <Icon className="check" type="check" theme="outlined" />
                        <Icon className="minus" type="minus" theme="outlined" />
                      </div>
                    )}
                    <div style={{ width: "100%", height: "100%" }}>
                      {this.renderMediaByType(media)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pagination-container">
          <Pagination
            showSizeChanger
            onChange={this.handleChangePagination}
            onShowSizeChange={this.handleShowSizeChange}
            current={currentPage}
            total={mediaCount}
            showTotal={total => `Total ${total} items`}
            showQuickJumper
          />
        </div>
        
        <Modal
          className="modal-media-file"
          width="50%"
          visible={showFileModal}
          title={currentMediaName}
          footer={
            <div className="modal-file-footer">
              <div className="left">
                <ul>
              
                  {
                    currentMediadataAltTh && currentMediaHeight && (
                      <li>
                        alt-th: {currentMediadataAltTh}
                      </li>
                  )
                  }
                  {currentMediadataAltEn && currentMediaHeight && (
                      <li>
                        alt-eh: {currentMediadataAltEn}
                      </li>
                  )}
                  {currentMediaWidth && currentMediaHeight && (
                    <li>
                      Dimensions: {currentMediaWidth}x{currentMediaHeight}
                    </li>
                  )}
                  {currentMediaDuration > 0 && (
                    <li>
                      Duration: {humanizeDuration(currentMediaDuration * 1000)}
                    </li>
                  )}
                  <li>Size: {filesize(currentMediaSize)}</li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  {authHasPermission(_.get(auth, "permissions"), [
                    `${permissionPrefix}.delete`,
                  ]) && (
                    <li>
                      <Popconfirm
                        title="Are you sure update this task?"
                        onConfirm={async () => {
                          if (updating) return;
                          this.setState({ updating: true });
                          const UpdateResult = await updateMediasApi(
                            currentMediaId, {}
                          );
                          if (_.get(UpdateResult, "status") === "success") {
                            message.success(
                              `Delete ${currentMediaName} success.`
                            );
                          } else {
                            message.error(
                              `${_.get(UpdateResult, "error_message")}`
                            );
                          }
                          this.setState({ updating: false });
                          this.setState({ showFileModal: null });
                          this.props.actions.getMedias();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" loading={deleting}>
                          Update
                        </Button>
                      </Popconfirm>
                    </li>
                    
                  )}
                </ul>
              </div>
              <div className="right">
                <ul>
                  {authHasPermission(_.get(auth, "permissions"), [
                    `${permissionPrefix}.delete`,
                  ]) && (
                    <li>
                      <Popconfirm
                        title="Are you sure delete this task?"
                        onConfirm={async () => {
                          if (deleting) return;
                          this.setState({ deleting: true });
                          const deleteResult = await deleteMediasApi(
                            currentMediaId
                          );
                          if (_.get(deleteResult, "status") === "success") {
                            message.success(
                              `Delete ${currentMediaName} success.`
                            );
                          } else {
                            message.error(
                              `${_.get(deleteResult, "error_message")}`
                            );
                          }
                          this.setState({ deleting: false });
                          this.setState({ showFileModal: null });
                          this.props.actions.getMedias();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" loading={deleting}>
                          Delete
                        </Button>
                      </Popconfirm>
                    </li>
                    
                  )}
                </ul>
              </div>
            </div>
          }
          onCancel={() => this.setState({ showFileModal: null })}
          afterClose={() => this.setState({ selectedFile: false })}
        >
          {this.renderMediaByTypeOnModal(currentMedia)}
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div className="media-page">
        <div className="page-header-container">
          <Affix>{this.renderPageHeader()}</Affix>
        </div>
        <div className="main-content-container">{this.renderMainContent()}</div>
      </div>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(Media);
