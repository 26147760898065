import React, { Component } from "react";
import _ from "lodash";
import { PageHeader, Affix, Form, Button, message } from "antd";
import { InputToFormItem } from "../../components";
import { withRedux } from "../../hoc";
import { updateProfileApi } from "../../services/auth";
import "./style.less";

const mapStateToProps = state => {
  return {
    auth: _.get(state, "auth.data"),
  };
};

const actionToProps = {};

class Profile extends Component {
  state = {
    submitting: false,
  };
  renderPageHeader = () => {
    const { submitting } = this.state;
    const { title } = this.props;
    const extra = [];
    extra.push(
      <Button key="save" type="primary" htmlType="submit" loading={submitting}>
        {submitting ? "Saving..." : "Save"}
      </Button>
    );
    return (
      <div className="page-header">
        <PageHeader title={title} subTitle={null} extra={extra}></PageHeader>
      </div>
    );
  };
  renderMainContent = () => {
    const { form, auth } = this.props;
    return (
      <div className="main-content">
        <InputToFormItem
          form={form}
          formItems={[
            {
              field: "email",
              label: "Email",
              inputType: "text",
              defaultValue: _.get(auth, "email"),
            },
            {
              field: "username",
              label: "Username",
              inputType: "text",
              defaultValue: _.get(auth, "username"),
            },
            {
              field: "password",
              label: "Password",
              inputType: "password",
            },
          ]}
        />
      </div>
    );
  };
  _update = async data => {
    const updated = await updateProfileApi(data);
    if (_.get(updated, "status") !== "success") {
      message.error(_.get(updated, "error_message", "Update failed."));
      return;
    }
    message.success("Update success.");
  };
  submit = async data => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    await this._update(data);
    this.setState({ submitting: false });
  };
  handleSubmit = e => {
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 96,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        this.submit(values);
      }
    });
  };
  render() {
    return (
      <div className="profile-page">
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <div className="page-header-container">
            <Affix>{this.renderPageHeader()}</Affix>
          </div>
          <div className="main-content-container">
            {this.renderMainContent()}
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create()(
  withRedux(mapStateToProps, actionToProps)(Profile)
);
