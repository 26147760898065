import * as ActionType from "../actions/actionType";
import { getLanguagesApi } from "../services/languages";

const switchLanguage = language => ({
  type: ActionType.SWITCH_LANGUAGE,
  language
});

const getLanguages = () => {
  return dispatch => {
    return dispatchLanguages(dispatch);
  };
};

const dispatchLanguages = dispatch => {
  return getLanguagesApi().then(data => {
    dispatch({
      type: ActionType.RECEIVE_LANGUAGES,
      data
    });
    return data;
  });
};

export { switchLanguage, getLanguages, dispatchLanguages };
