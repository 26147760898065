import _ from "lodash";
import { fetchApi } from "../../utils";

const getSettingsApi = () => {
  return fetchApi("settings").then(res => _.get(res, "result"));
};

const saveSettingsApi = data => {
  return fetchApi("settings", "POST", JSON.stringify(data));
};

export { getSettingsApi, saveSettingsApi };
