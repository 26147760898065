import { fetchApi, removeEmpty } from "../utils";

const loginApi = (uid, password) => {
  const data = JSON.stringify({ uid, password });
  return fetchApi("auth/login", "POST", data);
};

const logoutApi = () => {
  return fetchApi("auth/logout", "POST", null);
};

const getAuthByTokenApi = token => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return fetchApi("auth/me", "GET", {}, headers);
};

const updateProfileApi = data => {
  return fetchApi("auth/update", "PUT", JSON.stringify(removeEmpty(data)));
};

export { loginApi, logoutApi, getAuthByTokenApi, updateProfileApi };
