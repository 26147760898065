import { combineReducers } from "redux";
import auth from "./auth";
import settings from "./settings";
import languages from "./languages";
import medias from "./medias";

export default combineReducers({
  auth,
  settings,
  languages,
  medias
});
