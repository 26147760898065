import _ from "lodash";

export default {
  parse: search => {
    if (_.isEmpty(search)) return {};
    let obj = {};
    const arr = _.split(search.substr(1), "&");
    _.forEach(arr, value => {
      const tmp = _.split(value, "=");
      obj = {
        ...obj,
        [tmp[0]]: decodeURIComponent(tmp[1])
      };
    });
    return obj;
  },
  stringify: obj => {
    if (_.isEmpty(obj)) return "";
    let str = "";
    _.forEach(obj, (value, key) => {
      str += `${key}=${value}`;
    });
    return str;
  }
};
