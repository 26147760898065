import * as ActionType from "../actions/actionType";
import { getMediasApi } from "../services/medias";

const getMedias = () => {
  return dispatch => {
    return dispatchMedias(dispatch);
  };
};

const dispatchMedias = dispatch => {
  return getMediasApi().then(data => {
    dispatch({
      type: ActionType.RECEIVE_MEDIAS,
      data
    });
    return data;
  });
};

export { getMedias, dispatchMedias };
