import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import _ from "lodash";
import { withRedux } from "../../hoc";
import queryString from "../../utils/queryString";
import { switchLanguage } from "../../actions/languages";

const mapStateToProps = state => {
  return {
    languages: _.get(state, "languages")
  };
};

const actionToProps = { switchLanguage };

class LanguageSwitcher extends PureComponent {
  handleSwitchLanguage = language => {
    const { key } = language;
    const currentActive = _.get(this.props, "languages.active");
    if (currentActive !== key) {
      const parsed = queryString.parse(this.props.location.search);
      parsed.lang = key;
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?${queryString.stringify(parsed)}`
      });
      this.props.actions.switchLanguage(key);
      // window.location.reload();
    }
  };
  getMenu = (languages, currentActive) => {
    return (
      <Menu onClick={this.handleSwitchLanguage}>
        {_.compact(
          _.map(languages, language => {
            const { code, icon, name, is_enabled } = language;
            if (!is_enabled) return null;
            if (code === currentActive) return null;
            return (
              <Menu.Item key={code}>
                <span className={`flag-icon flag-icon-${icon}`} /> {name}{" "}
              </Menu.Item>
            );
          })
        )}
      </Menu>
    );
  };
  render() {
    const languages = _.get(this.props, "languages.data");
    const currentActive = _.get(this.props, "languages.active");
    if (_.isEmpty(languages)) return null;
    if (_.isEmpty(currentActive)) return null;
    const language = _.find(languages, { code: currentActive });
    return (
      <Dropdown
        className="language-switcher"
        overlay={this.getMenu(languages, currentActive)}
        trigger={["click"]}
        placement="topCenter"
      >
        <a className="ant-dropdown-link" href="#lang">
          <span className={`flag-icon flag-icon-${_.get(language, "icon")}`} /> {_.get(language, "name")}
        </a>
      </Dropdown>
    );
  }
}

export default withRouter(withRedux(mapStateToProps, actionToProps)(LanguageSwitcher));
