export default [
  {
    title: "All",
    key: "all",
    selectable: false,
    children: [
      {
        title: "Dashboard",
        key: "dashboard",
        selectable: false,
        children: [{ title: "View", key: "dashboard.view", selectable: false }],
      },
      {
        title: "Page",
        key: "page",
        selectable: false,
        children: [
          {
            title: "Home",
            key: "page.home",
            selectable: false,
            children: [
              { title: "View", key: "page.home.view", selectable: false },
              { title: "Save", key: "page.home.save", selectable: false },
            ],
          },
          {
            title: "About",
            key: "page.about",
            selectable: false,
            children: [
              { title: "View", key: "page.about.view", selectable: false },
              { title: "Save", key: "page.about.save", selectable: false },
            ],
          },
          {
            title: "Why Solar Energy",
            key: "page.why_solar_energy",
            selectable: false,
            children: [
              {
                title: "View",
                key: "page.why_solar_energy.view",
                selectable: false,
              },
              {
                title: "Save",
                key: "page.why_solar_energy.save",
                selectable: false,
              },
            ],
          },
          {
            title: "News",
            key: "page.news",
            selectable: false,
            children: [
              { title: "View", key: "page.news.view", selectable: false },
              { title: "Save", key: "page.news.save", selectable: false },
            ],
          },
          {
            title: "Customer Area",
            key: "page.customer_area",
            selectable: false,
            children: [
              {
                title: "View",
                key: "page.customer_area.view",
                selectable: false,
              },
              {
                title: "Save",
                key: "page.customer_area.save",
                selectable: false,
              },
            ],
          },
          {
            title: "Residential",
            key: "page.residential",
            selectable: false,
            children: [
              {
                title: "View",
                key: "page.residential.view",
                selectable: false,
              },
              {
                title: "Save",
                key: "page.residential.save",
                selectable: false,
              },
            ],
          },
          {
            title: "Commercial",
            key: "page.commercial",
            selectable: false,
            children: [
              { title: "View", key: "page.commercial.view", selectable: false },
              { title: "Save", key: "page.commercial.save", selectable: false },
            ],
          },
          {
            title: "Project",
            key: "page.project",
            selectable: false,
            children: [
              { title: "View", key: "page.project.view", selectable: false },
              { title: "Save", key: "page.project.save", selectable: false },
            ],
          },
          {
            title: "Config",
            key: "page.config",
            selectable: false,
            children: [
              { title: "View", key: "page.config.view", selectable: false },
              { title: "Save", key: "page.config.save", selectable: false },
            ],
          },
          {
            title: "EV Charger",
            key: "page.ev_charger",
            selectable: false,
            children: [
              { title: "View", key: "page.ev_charger.view", selectable: false },
              { title: "Save", key: "page.ev_charger.save", selectable: false },
            ],
          },
          {
            title: "Blog",
            key: "page.blog",
            selectable: false,
            children: [
              { title: "View", key: "page.blog.view", selectable: false },
              { title: "Save", key: "page.blog.save", selectable: false },
            ],
          },
        ],
      },
      {
        title: "Post",
        key: "post",
        selectable: false,
        children: [
          {
            title: "Posts",
            key: "post.posts",
            selectable: false,
            children: [
              { title: "View", key: "post.posts.view", selectable: false },
              { title: "Add", key: "post.posts.add", selectable: false },
              { title: "Edit", key: "post.posts.edit", selectable: false },
              { title: "Delete", key: "post.posts.delete", selectable: false },
            ],
          },
          {
            title: "Categories",
            key: "post.categories",
            selectable: false,
            children: [
              { title: "View", key: "post.categories.view", selectable: false },
              { title: "Add", key: "post.categories.add", selectable: false },
              { title: "Edit", key: "post.categories.edit", selectable: false },
              {
                title: "Delete",
                key: "post.categories.delete",
                selectable: false,
              },
            ],
          },
          {
            title: "Tags",
            key: "post.tags",
            selectable: false,
            children: [
              { title: "View", key: "post.tags.view", selectable: false },
              { title: "Add", key: "post.tags.add", selectable: false },
              { title: "Edit", key: "post.tags.edit", selectable: false },
              {
                title: "Delete",
                key: "post.tags.delete",
                selectable: false,
              },
            ],
          },
        ],
      },
      {
        title: "Faq",
        key: "faq",
        selectable: false,
        children: [
          {
            title: "Faqs",
            key: "faq.faqs",
            selectable: false,
            children: [
              { title: "View", key: "faq.faqs.view", selectable: false },
              { title: "Add", key: "faq.faqs.add", selectable: false },
              { title: "Edit", key: "faq.faqs.edit", selectable: false },
              { title: "Delete", key: "faq.faqs.delete", selectable: false },
            ],
          },
          {
            title: "Categories",
            key: "faq.categories",
            selectable: false,
            children: [
              { title: "View", key: "faq.categories.view", selectable: false },
              { title: "Add", key: "faq.categories.add", selectable: false },
              { title: "Edit", key: "faq.categories.edit", selectable: false },
              {
                title: "Delete",
                key: "faq.categories.delete",
                selectable: false,
              },
            ],
          },
        ],
      },
      {
        title: "Learn Hub",
        key: "learns",
        selectable: false,
        children: [
          { title: "View", key: "learns.view", selectable: false },
          { title: "Add", key: "learns.add", selectable: false },
          { title: "Edit", key: "learns.edit", selectable: false },
          { title: "Delete", key: "learns.delete", selectable: false },
        ],
      },
      {
        title: "Media Library",
        key: "medias",
        selectable: false,
        children: [
          { title: "View", key: "medias.view", selectable: false },
          { title: "Add", key: "medias.add", selectable: false },
          { title: "Edit", key: "medias.edit", selectable: false },
          { title: "Delete", key: "medias.delete", selectable: false },
        ],
      },
      // {
      //   title: "Project Categories",
      //   key: "project_categories",
      //   selectable: false,
      //   children: [
      //     { title: "View", key: "project_categories.view", selectable: false },
      //     { title: "Add", key: "project_categories.add", selectable: false },
      //     { title: "Edit", key: "project_categories.edit", selectable: false },
      //     {
      //       title: "Delete",
      //       key: "project_categories.delete",
      //       selectable: false,
      //     },
      //   ],
      // },
      {
        title: "Partners",
        key: "partners",
        selectable: false,
        children: [
          { title: "View", key: "partners.view", selectable: false },
          { title: "Save", key: "partners.save", selectable: false },
        ],
      },
      {
        title: "Projects",
        key: "projects",
        selectable: false,
        children: [
          { title: "View", key: "projects.view", selectable: false },
          { title: "Add", key: "projects.add", selectable: false },
          { title: "Edit", key: "projects.edit", selectable: false },
          {
            title: "Delete",
            key: "projects.delete",
            selectable: false,
          },
        ],
      },
      {
        title: "Google Sheets",
        key: "google_sheets",
        selectable: false,
        children: [
          { title: "View", key: "google_sheets.view", selectable: false },
          { title: "Add", key: "google_sheets.add", selectable: false },
          { title: "Edit", key: "google_sheets.edit", selectable: false },
          {
            title: "Delete",
            key: "google_sheets.delete",
            selectable: false,
          },
        ],
      },
      {
        title: "Solar",
        key: "solar",
        selectable: false,
        children: [
          {
            title: "Packages",
            key: "solar.packages",
            selectable: false,
            children: [
              { title: "View", key: "packages.view", selectable: false },
              { title: "Edit", key: "packages.edit", selectable: false },
            ],
          },
          {
            title: "Roof Types",
            key: "solar.roof_types",
            selectable: false,
            children: [
              { title: "View", key: "roof_types.view", selectable: false },
              { title: "Add", key: "roof_types.add", selectable: false },
              { title: "Edit", key: "roof_types.edit", selectable: false },
              {
                title: "Delete",
                key: "roof_types.delete",
                selectable: false,
              },
            ],
          },
          {
            title: "Meter Types",
            key: "solar.meter_types",
            selectable: false,
            children: [
              { title: "View", key: "meter_types.view", selectable: false },
              { title: "Add", key: "meter_types.add", selectable: false },
              { title: "Edit", key: "meter_types.edit", selectable: false },
              {
                title: "Delete",
                key: "meter_types.delete",
                selectable: false,
              },
            ],
          },
          {
            title: "Power Periods",
            key: "solar.power_periods",
            selectable: false,
            children: [
              { title: "View", key: "power_periods.view", selectable: false },
              { title: "Add", key: "power_periods.add", selectable: false },
              { title: "Edit", key: "power_periods.edit", selectable: false },
              {
                title: "Delete",
                key: "power_periods.delete",
                selectable: false,
              },
            ],
          },
        ],
      },
      {
        title: "Setting",
        key: "setting",
        selectable: false,
        children: [
          {
            title: "Contact",
            key: "setting.contact",
            selectable: false,
            children: [
              { title: "View", key: "setting.contact.view", selectable: false },
              { title: "Save", key: "setting.contact.save", selectable: false },
            ],
          },
          {
            title: "Email Payment",
            key: "setting.email",
            selectable: false,
            children: [
              { title: "View", key: "setting.email.view", selectable: false },
              { title: "Save", key: "setting.email.save", selectable: false },
            ],
          },
          {
            title: "Coupon Code",
            key: "setting.coupon",
            selectable: false,
            children: [
              { title: "View", key: "setting.coupon.view", selectable: false },
              { title: "Save", key: "setting.coupon.save", selectable: false },
            ],
          },
          {
            title: "Powerwall Landing",
            key: "setting.powerwall",
            selectable: false,
            children: [
              {
                title: "View",
                key: "setting.powerwall.view",
                selectable: false,
              },
              {
                title: "Save",
                key: "setting.powerwall.save",
                selectable: false,
              },
            ],
          },
        ],
      },
    ],
  },
];
