import React from "react";
import moment from "moment";
// import _ from "lodash";
// import permissions from "./permissions";
import { PackageImages } from "../components";

export default {
  medias: {
    resource: "medias",
    permissionPrefix: "medias",
  },
  languages: {
    resource: "languages",
    permissionPrefix: "setting.languages",
  },
  "page/home": {
    resource: "page/home",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.home",
    formItems: [
      {
        field: "banners",
        label: "Banners",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "1920x960px",
          },
          {
            field: "media_image_mobile",
            label: "Image Mobile",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "840x1565px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "title_shadow",
            label: "Title Shadow",
            inputType: "checkbox",
          },
        ],
      },
      {
        field: "power_everything_title",
        label: "Power Everything Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "power_everything_detail_1",
        label: "Power Everything Detail 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "power_everything_detail_2",
        label: "Power Everything Detail 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      // {
      //   field: "media_power_everything_videos",
      //   label: "Power Everything Videos",
      //   inputType: "media",
      //   inputOption: {
      //     multiple: true,
      //   },
      // },
      {
        field: "power_everything_videos",
        label: "Power Everything Videos",
        inputType: "repeat",
        formItems: [
          {
            field: "media_video",
            label: "Add Image/Video",
            inputType: "media",
            extra: "1920*960px",
          },
          {
            field: "title",
            label: "Title",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "media_the_aesthetic_world_background_image",
        label: "The Aesthetic World Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "the_aesthetic_world_title",
        label: "The Aesthetic World Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "the_aesthetic_world_detail_1",
        label: "The Aesthetic World Detail 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "the_aesthetic_world_detail_2",
        label: "The Aesthetic World Detail 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "the_aesthetic_world_items",
        label: "The Aesthetic World Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "150x150px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "storage_systems",
        label: "Storage Systems",
        inputType: "repeat",
        formItems: [
          {
            field: "media_logo",
            label: "Logo",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "background_color",
            label: "Background Color",
            inputType: "color-picker",
          },
          {
            field: "media_background_image",
            label: "Background Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "1920x960px",
          },
          {
            field: "items",
            label: "Items",
            inputType: "repeat",
            formItems: [
              {
                field: "media_icon",
                label: "Icon",
                inputType: "media",
                inputOption: {
                  supportExtensions: ["jpg", "jpeg", "png", "svg"],
                },
                extra: "150x150px",
              },
              {
                field: "name",
                label: "Name",
                inputType: "textarea",
                multiLanguage: true,
              },
            ],
          },
        ],
      },
      {
        field: "media_application_background_image",
        label: "Application Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "application_title",
        label: "Application Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "application_detail",
        label: "Application Detail",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "application_items",
        label: "Application Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "150x150px",
          },
          {
            field: "name",
            label: "Name",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
    ],
  },
  "page/about": {
    resource: "page/about",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.about",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "banner_detail",
        label: "Banner Detail",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "informations",
        label: "Informations",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "520x785px",
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "items_topic",
            label: "Items Topic",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "items",
            label: "Items",
            inputType: "repeat",
            formItems: [
              {
                field: "media_icon",
                label: "Icon",
                inputType: "media",
                inputOption: {
                  supportExtensions: ["jpg", "jpeg", "png", "svg"],
                },
                extra: "70x70px",
              },
              {
                field: "title",
                label: "Title",
                inputType: "text",
                multiLanguage: true,
              },
              {
                field: "detail",
                label: "Detail",
                inputType: "textarea",
                multiLanguage: true,
              },
            ],
          },
        ],
      },
      {
        field: "pride_topic",
        label: "Pride Topic",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "media_pride_image",
        label: "Pride Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "pride_detail",
        label: "Pride Detail",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "our_value",
        label: "Our Value",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "our_value_items",
        label: "Our Value Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "86x86px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
    ],
  },
  "page/why_solar_energy": {
    resource: "page/why_solar_energy",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.why_solar_energy",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banner_icon_items",
        label: "Banner Icon Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "100x100px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "text",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "informations",
        label: "Informations",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "500x375px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "wysiwyg",
            multiLanguage: true,
          },
        ],
      },
    ],
  },
  "page/customer_area": {
    resource: "page/customer_area",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.customer_area",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "text",
        multiLanguage: true,
      },
    ],
  },
  "page/news": {
    resource: "page/news",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.news",
    formItems: [],
  },
  "page/residential": {
    resource: "page/residential",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.residential",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "media_banner_image_mobile",
        label: "Banner Image Mobile",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "840x652px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banner_title_shadow",
        label: "Banner Title Shadow",
        inputType: "checkbox",
      },
      {
        field: "banner_detail",
        label: "Banner Detail",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banner_detail_shadow",
        label: "Banner Detail Shadow",
        inputType: "checkbox",
      },
      {
        field: "media_solution_to_smart_life_background_image",
        label: "Solution to Smart Life Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "solution_to_smart_life_title",
        label: "Solution to Smart Life Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "media_how_the_system_works_background_image",
        label: "How The System Works Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "media_how_the_system_works_background_image_mobile",
        label: "How The System Works Background Image Mobile",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "840x1300px",
      },
      {
        field: "how_the_system_works_items",
        label: "How The System Works Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "150x150px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "reduce_your_cost_with_us_title",
        label: "Reduce Your Cost With Us Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "reduce_your_cost_with_us_items",
        label: "Reduce Your Cost With Us Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_background_image",
            label: "Background Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "480x600px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "media_expertise_background_image",
        label: "Expertise Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "expertise",
        label: "Expertise",
        inputType: "number",
        extra: "Years",
      },
      {
        field: "expertise_detail_1",
        label: "Expertise Detail 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "expertise_detail_2",
        label: "Expertise Detail 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "media_client_experience_background_image",
        label: "Client Experience Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
    ],
  },
  "page/commercial": {
    resource: "page/commercial",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.commercial",
    formItems: [
      {
        field: "h1_title",
        label: "H1 Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "description",
        label: "Description",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banners",
        label: "Banners",
        inputType: "repeat",
        formItems: [
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "1920x960px",
          },
          {
            field: "shadow",
            label: "Shadow",
            inputType: "checkbox",
          },
        ],
      },
      {
        field: "our_professional_solutions_title",
        label: "Our Professional Solutions Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "our_professional_solutions_items",
        label: "Our Professional Solutions Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "1920x960px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "media_what_our_clients_will_get_background_image",
        label: "What Our Clients Will Get Background Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "what_our_clients_will_get_topic",
        label: "What Our Clients Will Get Topic",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "what_our_clients_will_get_items",
        label: "What Our Clients Will Get Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "150x150px",
          },
          {
            field: "name",
            label: "Name",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "services_and_warranty_title",
        label: "Services And Warranty Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "services_and_warranty_items",
        label: "Services And Warranty Items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_icon",
            label: "Icon",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg"],
            },
            extra: "100x100px",
          },
          {
            field: "title",
            label: "Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "media_services_and_warranty_image",
        label: "Services And Warranty Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "960x960px",
      },
    ],
  },
  "page/project": {
    resource: "page/project",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.project",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banner_detail",
        label: "Banner Detail",
        inputType: "textarea",
        multiLanguage: true,
      },
    ],
  },
  "page/config": {
    resource: "page/config",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
    },
    permissionPrefix: "page.config",
    formItems: [
      {
        field: "solar_cell_product",
        label: "Solar cell product",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "solar_cell_logo",
        label: "Solar cell logo brand",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "inverter_product",
        label: "Inverter product",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "inverter_logo",
        label: "Inverter logo brand",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "powerwall_product",
        label: "Powerwall product",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "powerwall_logo",
        label: "Powerwall logo brand",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
      },
      {
        field: "powerwall_price",
        label: "Powerwall price",
        inputType: "number",
      },
      {
        field: "powerwall_max_qty",
        label: "Max Powerwall Quantity",
        inputType: "number",
      },
      {
        field: "deposit_percent",
        label: "Deposit Percent",
        inputType: "number",
      },
      {
        field: "detail_agreement_payment_1",
        label: "Detail Agreement Payment 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "detail_agreement_payment_2",
        label: "Detail Agreement Payment 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "package_images",
        label: "Package Images",
        inputType: "custom",
        component: <PackageImages />,
      },
    ],
  },
  "page/learn": {
    resource: "page/learn",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.learn",
    formItems: [
      {
        field: "media_banner_image",
        label: "Banner Image",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "media_banner_image_mobile",
        label: "Banner Image Mobile",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "840x652px",
      },
      {
        field: "banner_title",
        label: "Banner Title",
        inputType: "textarea",
        multiLanguage: true,
      },
    ],
  },
  "page/powerwall": {
    resource: "page/powerwall",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.powerwall",
    formItems: [
      {
        field: "media_banner_section_1_image",
        label: "Banner Image Section 1",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x960px",
      },
      {
        field: "media_banner_section_1_mobile",
        label: "Banner Image Mobile Section 1",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "840x652px",
      },
      {
        field: "banner_section_1_title_h1",
        label: "Banner Title H1 Section 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "banner_section_1_detail",
        label: "Banner Detail Section 1",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "media_section_2_video",
        label: "Add Image/Video Section 2",
        inputType: "media",
        extra: "1920*960px",
      },
      {
        field: "section_2_title_h2",
        label: "Title H2 Section 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "section_2_content_h3",
        label: "Content H3 Section 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "section_2_detail",
        label: "Detail Section 2",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "section_3_mutiple_contents",
        label: "Section 3 Mutiple Contents",
        inputType: "repeat",
        formItems: [
          {
            field: "media_section_3_desktop",
            label: " Image Desktop Section 3",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "1920x960px",
          },
          {
            field: "media_section_3_mobile",
            label: "Image Mobile Section 3",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "840x652px",
          },
          {
            field: "media_section_3_tablet",
            label: "Image Tablet Section 3",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "820x910px",
          },
          {
            field: "title_h3",
            label: "Title H3",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "title_h2",
            label: "Title H2",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_4_mutiple_contents",
        label: "Section 4 Mutiple Contents",
        inputType: "repeat",
        formItems: [
          {
            field: "media_section_4_desktop",
            label: " Image Desktop Section 4",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "1920x960px",
          },
          {
            field: "title_h3",
            label: "Title H3",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "title_h2",
            label: "Title H2",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_5_mutiple_contents_left",
        label: "Section 5 Mutiple Contents Left",
        inputType: "repeat",
        formItems: [
          {
            field: "media_section_5_desktop",
            label: " Image Desktop Section 5",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "1920x960px",
          },
          {
            field: "tag_p",
            label: "Tag P",
            inputType: "text",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_5_mutiple_contents_right",
        label: "Section 5 Mutiple Contents Right",
        inputType: "repeat",
        formItems: [
          {
            field: "title_h3",
            label: "Title H3",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "title_h2",
            label: "Title H2",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "wysiwyg",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_6_mutiple_contents",
        label: "Section 6 Mutiple Contents",
        inputType: "repeat",
        formItems: [
          {
            field: "media_section_6_desktop",
            label: " Image Desktop Section 6",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "1920x960px",
          },
          {
            field: "media_section_6_mobile",
            label: "Image Mobile Section 6",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "840x652px",
          },
          {
            field: "title_h3",
            label: "Title H3",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "title_h2",
            label: "Title H2",
            inputType: "text",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "section_6_sub_mutiple_contents",
            label: "Section 6 Sub Mutiple Contents",
            inputType: "repeat",
            formItems: [
              {
                field: "media_sub_section_6_desktop",
                label: " Image Desktop Section 6",
                inputType: "media",
                inputOption: {
                  supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
                },
                extra: "1920x960px",
              },
              {
                field: "media_sub_section_6_mobile",
                label: "Image Sub Mobile Section 6",
                inputType: "media",
                inputOption: {
                  supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
                },
                extra: "840x652px",
              },
              {
                field: "title_h3",
                label: "Title H3",
                inputType: "text",
                multiLanguage: true,
              },
              {
                field: "title_h2",
                label: "Title H2",
                inputType: "text",
                multiLanguage: true,
              },
              {
                field: "detail",
                label: "Detail",
                inputType: "textarea",
                multiLanguage: true,
              },
            ],
          },
        ],
      },
      {
        field: "section_7_title_h2",
        label: "Section 7 Title H2",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "section_7_mutiple_contents",
        label: "Section 7 Mutiple Contents",
        inputType: "repeat",
        formItems: [
          {
            field: "media_section_7_desktop",
            label: " Image Desktop Section 7",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "1920x960px",
          },
        ],
      },
    ],
  },
  "page/ev_charger": {
    resource: "page/ev_charger",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.ev_charger",
    formItems: [
      {
        field: "media_section_1_background_image",
        label: "Section 1 Background",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
        },
        extra: "1920x960px",
      },
      {
        field: "media_section_1_title_logo_image",
        label: "Section 1 Title Logo",
        inputType: "media",
        inputOption: {
          supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
        },
        extra: "840x652px",
      },
      {
        field: "section_1_sub_title",
        label: "Section 1 Sub Tile",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "section_2_items",
        label: "Section 2 Chargers Preview",
        inputType: "repeat",
        formItems: [
          {
            field: "media_background_image",
            label: "Background",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "150x150px",
          },
          {
            field: "sub_title",
            label: "Sub Title",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_3_items",
        label: "Section 3 Chargers",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "480x600px",
          },
          {
            field: "sub_title",
            label: "Sub Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "wysiwyg",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_4_title",
        label: "Section 4 FAQ",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "section_4_items",
        label: "Section 4 FAQ Items",
        inputType: "repeat",
        formItems: [
          {
            field: "sub_title",
            label: "Sub Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "wysiwyg",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "section_5_title",
        label: "Section 5 Customer Review",
        inputType: "text",
        multiLanguage: true,
      },
      {
        field: "section_5_items",
        label: "Section 5 Customer Review items",
        inputType: "repeat",
        formItems: [
          {
            field: "media_image",
            label: "Image",
            inputType: "media",
            inputOption: {
              supportExtensions: ["jpg", "jpeg", "png", "svg", "webp"],
            },
            extra: "480x600px",
          },
          {
            field: "sub_title",
            label: "Sub Title",
            inputType: "textarea",
            multiLanguage: true,
          },
          {
            field: "detail",
            label: "Detail",
            inputType: "wysiwyg",
            multiLanguage: true,
          },
        ],
      },
    ],
  },
  "page/blog": {
    resource: "page/blog",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: true,
    },
    permissionPrefix: "page.blog",
    formItems: [],
  },
  post_categories: {
    resource: "post_categories",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "post_categories",
    formItems: [
      {
        field: "slug",
        label: "Slug",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Slug!" }],
        },
        tableDisplay: true,
      },
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  post_tags: {
    resource: "post_tags",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "post_tags",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  posts: {
    resource: "posts",
    otherResource: ["post_categories", "post_tags"],
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: true,
      sort: true,
      showCreatedAt: true,
      seo: true,
    },
    permissionPrefix: "posts",
    formItems: [
      {
        field: "slug",
        label: "Slug",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Slug!" }],
        },
        tableDisplay: true,
      },
      {
        field: "title",
        label: "Title",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Title!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "short_description",
        label: "Short Description",
        inputType: "textarea",
        multiLanguage: true,
      },
      {
        field: "description",
        label: "Full Description",
        inputType: "wysiwyg",
        multiLanguage: true,
      },
      {
        field: "image_id",
        label: "Image",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        // tableDisplay: true,
        extra: "1920x960px",
      },
      {
        field: "thumbnail_image_id",
        label: "Thumbnail Image",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        tableDisplay: true,
        extra: "265x200px",
      },
      {
        field: "post_category_id",
        label: "Category",
        inputType: "select",
        tableDisplay: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please select Category!" }],
        },
        relation: {
          resource: "post_categories",
          mapResource: {
            id: "id",
            label: "name",
          },
          addMore: true,
        },
        filter: {},
      },
      {
        field: "post_tag_id",
        label: "Tag",
        inputType: "select",
        // tableDisplay: true,
        // fieldDecorator: {
        //   rules: [{ required: true, message: "Please select Tag!" }],
        // },
        relation: {
          resource: "post_tags",
          mapResource: {
            id: "id",
            label: "name",
          },
          addMore: true,
        },
        filter: {},
      },
      {
        field: "reference_url",
        label: "Reference Url",
        inputType: "text",
        fieldDecorator: {
          rules: [
            {
              type: "url",
              message: "Reference Url is not a valid url!",
            },
          ],
        },
      },
      {
        field: "post_date",
        label: "Post Date",
        inputType: "date-picker",
        defaultValue: moment(),
      },
    ],
  },
  faq_categories: {
    resource: "faq_categories",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "faq_categories",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  faqs: {
    resource: "faqs",
    otherResource: ["faq_categories"],
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "faqs",
    formItems: [
      {
        field: "question",
        label: "Question",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Question!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "answer",
        label: "Answer",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Answer!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "faq_category_id",
        label: "Category",
        inputType: "select",
        tableDisplay: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please select Category!" }],
        },
        relation: {
          resource: "faq_categories",
          mapResource: {
            id: "id",
            label: "name",
          },
          addMore: true,
        },
        filter: {},
      },
    ],
  },
  project_categories: {
    resource: "project_categories",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      showCreatedAt: true,
    },
    permissionPrefix: "project_categories",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  projects: {
    resource: "projects",
    otherResource: ["project_categories"],
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "projects",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "installation_size",
        label: "Installation Size",
        inputType: "text",
        fieldDecorator: {
          rules: [
            { required: true, message: "Please input Installation Size!" },
          ],
        },
        tableDisplay: true,
      },
      {
        field: "energy_saving",
        label: "Energy Saving",
        inputType: "number",
      },
      {
        field: "project_category_id",
        label: "Category",
        inputType: "select",
        tableDisplay: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please select Category!" }],
        },
        relation: {
          resource: "project_categories",
          mapResource: {
            id: "id",
            label: "name",
          },
          // addMore: true,
        },
        filter: {},
      },
      {
        field: "thumbnail_image_id",
        label: "Thumbnail Image",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        tableDisplay: true,
        extra: "380x450px",
      },
      {
        field: "images",
        label: "Gallery",
        inputType: "media",
        inputOption: {
          multiple: true,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "1920x1080px",
      },
    ],
  },
  "page/partners": {
    resource: "page/partners",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: false,
    },
    permissionPrefix: "partners",
    formItems: [
      {
        field: "media_partners",
        label: "Partners",
        inputType: "media",
        inputOption: {
          multiple: true,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        extra: "170x100px",
      },
    ],
  },
  google_sheets: {
    resource: "google_sheets",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      showCreatedAt: false,
      showUpdatedAt: true,
    },
    permissionPrefix: "google_sheets",
    formItems: [
      {
        field: "spreadsheet_id",
        label: "Spreadsheet Id",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Spreadsheet Id!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "in_use",
        label: "In Use",
        inputType: "switch",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input in use" }],
        },
        tableDisplay: true,
        // search: true,
      },
    ],
  },
  packages: {
    resource: "packages",
    resourceType: "view",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      showCreatedAt: false,
    },
    permissionPrefix: "packages",
    formItems: [
      {
        field: "system",
        label: "System",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      // {
      //   field: "brand",
      //   label: "Brand",
      //   inputType: "text",
      //   tableDisplay: true,
      //   search: true,
      // },
      {
        field: "power",
        label: "Module (watt)",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      {
        field: "qty",
        label: "Qty (Panel)",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      {
        field: "wp",
        label: "Power (kW)",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      {
        field: "price",
        label: "Price",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      {
        field: "align_value",
        label: "Align Value",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
      {
        field: "excel_value",
        label: "Excel Value",
        inputType: "text",
        tableDisplay: true,
        search: true,
      },
    ],
  },
  roof_types: {
    resource: "roof_types",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "roof_types",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
        multiLanguage: true,
      },
      {
        field: "roof_icon_id",
        label: "Icon",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        tableDisplay: true,
      },
      {
        field: "roof_icon_active_id",
        label: "Icon Active",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        tableDisplay: true,
      },
    ],
  },
  meter_types: {
    resource: "meter_types",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "meter_types",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
        multiLanguage: true,
      },
      {
        field: "align_value",
        label: "Align Value",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Align Value!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "excel_value",
        label: "Excel Value",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Excel Value!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  power_periods: {
    resource: "power_periods",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: true,
      showCreatedAt: true,
    },
    permissionPrefix: "power_periods",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "align_value",
        label: "Align Value",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Align Value!" }],
        },
        tableDisplay: true,
        search: true,
      },
      {
        field: "excel_value",
        label: "Excel Value",
        inputType: "text",
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Excel Value!" }],
        },
        tableDisplay: true,
        search: true,
      },
    ],
  },
  "page/setting_contact": {
    resource: "page/setting_contact",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: false,
    },
    permissionPrefix: "setting",
    formItems: [
      {
        field: "addresses",
        label: "Addresses",
        inputType: "repeat",
        formItems: [
          {
            field: "address",
            label: "Address",
            inputType: "textarea",
            multiLanguage: true,
          },
        ],
      },
      {
        field: "residential_phone",
        label: "Residential Phone",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "commercial_phone",
        label: "Commercial Phone",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "fax",
        label: "Fax",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "phone",
        label: "Phone",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email",
        label: "Email",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email_residential",
        label: "Contact Us Email Residential",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email_commercial",
        label: "Contact Us Email Commercial",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email_powerwall",
        label: "Contact Us Email Powerwall",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email_payment_contact_sale",
        label: "Payment Email Contact Sale",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
      {
        field: "email_pay_now",
        label: "Payment Email Pay Now",
        inputType: "select",
        inputOption: {
          mode: "tags",
          tokenSeparators: [","],
        },
      },
    ],
  },
  "page/setting_email": {
    resource: "page/setting_email",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: false,
    },
    permissionPrefix: "email",
    formItems: [
      {
        field: "title",
        label: "Title",
      },
      {
        field: "rule",
        label: "Rule",
        inputType: "wysiwyg",
      },
      {
        field: "contact",
        label: "Contact",
        inputType: "wysiwyg",
      },
    ],
  },
  "page/setting_coupon": {
    resource: "page/setting_coupon",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: false,
    },
    permissionPrefix: "coupon",
    formItems: [
      {
        field: "coupon_code",
        label: "Coupon Code",
      },
      {
        field: "coupon_discount",
        label: "Coupon Discount",
      },
      {
        field: "deposit",
        label: "เงินมัดจำ",
      },
    ],
  },
  "page/setting_powerwall": {
    resource: "page/setting_powerwall",
    resourceType: "page",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
      seo: false,
    },
    permissionPrefix: "powerwall",
    formItems: [
      {
        field: "powerwall_total",
        label: "จำนวน Powerwall ทั้งหมด",
        inputType: "number",
      },
      {
        field: "powerwall_sold_count",
        label: "จำนวน Powerwall ที่ขายไปแล้ว",
        inputType: "number",
      },
    ],
  },
  learn_categories: {
    resource: "learn_categories",
    enableModule: {
      publish: false,
      publishTime: false,
      categories: false,
      tags: false,
      recommend: false,
      sort: false,
    },
    permissionPrefix: "learns",
    formItems: [
      {
        field: "name",
        label: "Name",
        inputType: "text",
        multiLanguage: true,
        fieldDecorator: {
          rules: [{ required: true, message: "Please input Name!" }],
        },
      },
      {
        field: "parent_id",
        label: "Parent",
        inputType: "tree-select",
        defaultValue: 0,
      },
      {
        field: "image_id",
        label: "Image",
        inputType: "media",
        inputOption: {
          multiple: false,
          supportExtensions: ["jpg", "jpeg", "png", "svg"],
        },
        // extra: "1920x960px",
      },
      {
        field: "description",
        label: "Description",
        inputType: "wysiwyg",
        multiLanguage: true,
      },
    ],
  },
};
