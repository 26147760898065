import React, { Component } from "react";
import { Button, Form, message, Drawer } from "antd";
import _ from "lodash";
import { InputToFormItem } from "../../../components";
import { fetchApi, convertDataToSubmit } from "../../../utils";

class OtherForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.visible, this.props.visible)) {
      if (this.props.visible) {
        this.autoFocusFirstInput();
      }
    }
  }
  autoFocusFirstInput = () => {
    setTimeout(() => {
      const firstElement = document.querySelector(
        ".ant-drawer .ant-form-item-children"
      );
      if (_.get(firstElement, "childNodes")) firstElement.childNodes[0].focus();
    }, 200);
  };
  _create = async data => {
    const { resource } = this.props;
    const created = await fetchApi(resource, "POST", JSON.stringify(data));
    if (_.get(created, "status") !== "success") {
      message.error(_.get(created, "error_message", "Create failed."));
      return;
    }
    message.success("Create success.");
    this.props.onCreated(created.result);
  };
  submit = async data => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    await this._create(data);
    this.setState({ submitting: false });
  };
  handleSubmit = e => {
    const { formItems, enableModule, languages } = this.props;
    e.preventDefault();
    const options = {
      scroll: {
        offsetTop: 39,
      },
    };
    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        const data = convertDataToSubmit({
          values,
          formItems,
          languages,
        });
        if (_.get(enableModule, "tags") === true) {
          data.tags = JSON.stringify(data.tags);
        }
        this.submit(data);
      }
    });
  };
  render() {
    const { submitting } = this.state;
    const { form, formItems, formItem, visible } = this.props;
    return (
      <Drawer
        {...this.props}
        title={_.get(formItem, "label")}
        visible={visible}
        width="50%"
        height="100%"
      >
        <Form onSubmit={this.handleSubmit}>
          <InputToFormItem form={form} formItems={formItems} />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              {submitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}

export default Form.create()(OtherForm);
