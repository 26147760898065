import React, { Component } from "react";
import { Form, Icon, Input, Button, Alert } from "antd";
import _ from "lodash";

import { Logo } from "../../components";
import { login } from "../../actions/auth";
import { withRedux } from "../../hoc";
import modifyVars from "../../config/modifyVars";

import "./style.less";

const FormItem = Form.Item;

const mapStateToProps = state => {
  return {
    shopName: _.get(state, "settings.data.shop_name"),
    shopLogo: _.get(state, "settings.data.media_shop_logo_data.fullpath"),
    shopBackground: _.get(
      state,
      "settings.data.media_shop_background_data.fullpath"
    ),
  };
};

const actionToProps = {
  login,
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      errorMessage: null,
    };
  }
  login = async (uid, password) => {
    if (this.state.submitting) return;
    this.setState({ submitting: true, errorMessage: null });
    const loginResult = await this.props.actions.login(uid, password);
    if (_.get(loginResult, "status") !== "success") {
      this.setState({
        submitting: false,
        errorMessage: _.get(loginResult, "error_message", loginResult),
      });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { uid, password } = values;
        this.login(uid, password);
      }
    });
  };
  render() {
    const { submitting, errorMessage } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="login-page">
        <div className="left">
          <div className="content-block">
            <div className="logo-block">
              <Logo color={modifyVars["@primary-color"]} width={250} />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="content-block">
            <div className="login-form-block">
              <h2>Login</h2>
              {errorMessage && <Alert message={errorMessage} type="error" />}
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem>
                  {getFieldDecorator("uid", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ],
                  })(
                    <Input
                      autoFocus
                      prefix={<Icon type="user" style={{ color: "#ffffff" }} />}
                      placeholder="Username"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: "#ffffff" }} />}
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </FormItem>
                <FormItem className="login-button-form-item">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={submitting}
                    ghost
                    shape="round"
                    block
                  >
                    {submitting ? "Logging..." : "Log in"}
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(withRedux(mapStateToProps, actionToProps)(Login));
