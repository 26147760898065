import _ from "lodash";
import * as ActionType from "./actionType";
import { loginApi, logoutApi, getAuthByTokenApi } from "../services/auth";
import { authHasPermission } from "../utils";
import { loadResource } from "../actions";

const setLogin = (dispatch, data) => {
  dispatch({
    type: ActionType.RECEIVE_AUTH,
    data,
  });
  dispatch({
    type: ActionType.IS_LOGIN,
    isLogin: true,
  });
};

const login = (uid, password) => {
  return async dispatch => {
    return loginApi(uid, password).then(loginResult => {
      const token = _.get(loginResult, "result.token");
      if (!token) return loginResult;
      window.localStorage.setItem("token", token);
      return getAuthByTokenApi(token).then(userResult => {
        const userData = _.get(userResult, "result");
        if (!userData) return userResult;
        if (!authHasPermission(_.get(userData, "permissions")))
          return { status: "error", message: "You are not allowed" };
        setLogin(dispatch, userData);
        loadResource(dispatch);
        return userData;
      });
    });
  };
};

const logout = () => {
  return dispatch => {
    logoutApi();
    window.localStorage.removeItem("token");
    dispatch({
      type: ActionType.IS_LOGIN,
      isLogin: false,
    });
  };
};

const getAuthByToken = async dispatch => {
  const token = window.localStorage.getItem("token");
  const result = await getAuthByTokenApi(token).then(userResult => {
    const userData = _.get(userResult, "result");
    if (!userData) return userResult;
    if (!authHasPermission(_.get(userData, "permissions")))
      return { status: "error", message: "You are not allowed" };
    setLogin(dispatch, userData);
    return userData;
  });
  dispatch({
    type: ActionType.LOGIN_CHECKED,
    checked: true,
  });
  return result;
};

export { login, logout, getAuthByToken };
