import React from "react";
import { Layout, Menu, Button, Row, Col, Icon } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Logo, LanguageSwitcher } from "../components";
import { withRedux } from "../hoc";
import { logout } from "../actions/auth";
import { authHasPermission } from "../utils";
import menus from "./menus";
import appConfig from "./app";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const THEME = appConfig.defaultTheme;

const mapStateToProps = state => {
  return {
    languages: _.get(state, "languages"),
    auth: _.get(state, "auth.data"),
    shopLogo: _.get(state, "settings.data.media_shop_logo_data.fullpath"),
  };
};

const actionToProps = { logout };

class MyLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: window.localStorage.getItem("theme") || THEME,
    };
  }
  renderMenus = () => {
    const { auth } = this.props;
    const getMenu = menus => {
      return _.compact(
        _.map(menus, menu => {
          const { key, title, link, icon, children, permissions } = menu;
          if (!authHasPermission(_.get(auth, "permissions"), permissions))
            return null;
          if (children) {
            return (
              <SubMenu
                key={key}
                title={
                  <span>
                    <Link to={link}>
                      {icon && <Icon type={icon} />}
                      <span className="nav-text">{title}</span>
                    </Link>
                  </span>
                }
              >
                {getMenu(children)}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={key}>
                <Link to={link}>
                  {icon && <Icon type={icon} />}
                  <span className="nav-text">{title}</span>
                </Link>
              </Menu.Item>
            );
          }
        })
      );
    };
    return getMenu(menus);
  };
  render() {
    const { theme } = this.state;
    const {
      subMenuActiveKey,
      menuActiveKey,
      component: Component,
      auth,
      shopLogo,
      languages,
    } = this.props;
    const username = _.get(auth, "username");
    return (
      <Layout>
        <Sider
          theme={theme}
          width={230}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <Menu
            theme={theme}
            mode="inline"
            selectable={false}
            style={{ flex: 0 }}
          >
            <Menu.ItemGroup key="group-1">
              <div
                className="logo-block"
                style={{
                  maxWidth: "80%",
                  margin: "auto",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <Logo src={shopLogo} />
              </div>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="group-2" title="Profile">
              <Menu.Item key="profile">
                <div
                  className="user-block"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link to="/profile">
                    <div
                      className="username"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: 110,
                        color: "rgba(255, 255, 255, 0.65)",
                      }}
                    >
                      {username}
                    </div>
                  </Link>
                  <Button onClick={() => this.props.actions.logout()}>
                    Logout
                  </Button>
                </div>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
          <Menu
            theme={theme}
            mode="inline"
            defaultOpenKeys={subMenuActiveKey}
            defaultSelectedKeys={menuActiveKey}
            style={{ flex: 1 }}
          >
            <Menu.ItemGroup key="group-1" title="Menu">
              {this.renderMenus()}
            </Menu.ItemGroup>
          </Menu>
          <Menu
            theme={theme}
            mode="inline"
            selectable={false}
            style={{ flex: 0 }}
          >
            <Menu.Divider />
            {/* <Menu.Item key="theme">
              <Row type="flex" justify="space-between">
                <Col>Theme: </Col>
                <Col>
                  <Switch
                    checked={this.state.theme === "dark"}
                    onChange={value => {
                      const theme = value ? "dark" : "light";
                      this.setState({ theme });
                      window.localStorage.setItem("theme", theme);
                    }}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
                </Col>
              </Row>
            </Menu.Item> */}
            {_.size(languages) > 1 && (
              <Menu.Item key="language">
                <Row type="flex" justify="space-between">
                  <Col>Language: </Col>
                  <Col>
                    <LanguageSwitcher />
                  </Col>
                </Row>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: 230 }}>
          <Content style={{ backgroundColor: "#ffffff" }}>
            <Component {...this.props} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(MyLayout);
