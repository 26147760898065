import React, { Component } from "react";
import {
  PageHeader,
  Button,
  Affix,
  Row,
  Col,
  Form,
  message,
  Popconfirm,
  Select,
  Divider,
  Icon,
  TreeSelect,
  Collapse,
  Tabs,
} from "antd";
import _ from "lodash";
import { InputToFormItem } from "../../../components";
import { withRedux } from "../../../hoc";
import {
  fetchApi,
  authHasPermission,
  mapCategoriesLevel,
  convertDataToSubmit,
  translateData,
} from "../../../utils";
import resources from "../../../config/resources";
import OtherForm from "./OtherForm";
import "./style.less";

const { Option } = Select;
const { SHOW_ALL } = TreeSelect;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const mapStateToProps = state => {
  return {
    t: _.get(state, "t"),
    languages: _.get(state, "languages"),
    auth: _.get(state, "auth.data"),
  };
};

const actionToProps = {};

class LayoutSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      deleting: false,
      formItems: props.formItems,
      item: {},
      // items: [],
      otherItems: [],
      categories: [],
      addOtherItem: null,
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async () => {
    await this.fetchOtherItems();
    this.fetchItem();
    // this.fetchItems();
    this.fetchCategories();
    this.autoFocusFirstInput();
  };
  mapFormItems = (formItems, translateItem = {}) => {
    const { item, otherItems } = this.state;
    const { t, form } = this.props;
    const { getFieldsValue } = form;
    const id = _.toInteger(this.props.match.params.id);
    const formData = getFieldsValue();
    const newFormItems = _.map(formItems, (formItem, index) => {
      const {
        field,
        inputType,
        relation,
        disabledOnEdit,
        noEditIds,
        inputOption,
        inputAfterField,
        defaultValue,
      } = formItem;
      const result = {
        ...formItem,
        inputOption: {
          ...inputOption,
        },
        defaultValue: _.get(translateItem, field, defaultValue),
      };
      if (inputType === "repeat") {
        return {
          ...result,
          formItems: this.mapFormItems(formItem.formItems),
        };
      }
      if (relation) {
        const otherItem = _.get(otherItems, relation.resource);
        const otherResource = _.get(resources, relation.resource);
        const translateOtherItems = _.map(otherItem, item =>
          translateData({
            item,
            t,
            formItems: _.get(otherResource, "formItems"),
          })
        );
        if (inputType === "select") {
          result.inputOption.children = _.map(translateOtherItems, value => {
            return (
              <Option value={_.get(value, relation.mapResource.id)}>
                {_.get(value, relation.mapResource.label)}
              </Option>
            );
          });
          result.inputOption.dropdownRender = menu => {
            return (
              <div>
                {menu}
                {_.get(relation, "addMore") && (
                  <>
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{ padding: "8px", cursor: "pointer" }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => {
                        this.setState({
                          addOtherItem: {
                            ...otherResource,
                            formItem,
                          },
                        });
                      }}
                    >
                      <Icon type="plus" /> Add item
                    </div>
                  </>
                )}
              </div>
            );
          };
        } else if (inputType === "tree-select") {
          const categoriesConvetedData = mapCategoriesLevel(
            translateOtherItems
          );
          result.inputOption.treeData = categoriesConvetedData;
          // result.defaultValue = _.map(_.get(translateItem, field), value => {
          //   return _.get(value, relation.mapResource.id);
          // });
        }
      }

      let disabled = false;
      if (
        (id && disabledOnEdit === true) ||
        _.includes(noEditIds, id) ||
        _.get(inputOption, "disabled", false) === true ||
        (inputAfterField &&
          !_.get(formData, inputAfterField) &&
          !_.get(item, inputAfterField))
      ) {
        disabled = true;
      }

      result.inputOption.disabled = disabled;

      return result;
    });
    return newFormItems;
  };
  getFormItems = () => {
    const { item, formItems } = this.state;
    // const { t } = this.props;
    // const translateItem = translateData({ item, t, formItems });
    const newFormItems = this.mapFormItems(formItems, item);
    return newFormItems;
  };
  fetchItem = async () => {
    const { resourceType } = this.props;
    const id = _.toInteger(this.props.match.params.id);
    const { resource, redirectPath } = this.props;
    let item = {};
    if (resourceType === "page") {
      item = await fetchApi(`${resource}`).then(res => _.get(res, "result"));
    } else {
      if (id)
        item = await fetchApi(`${resource}/${id}`).then(res =>
          _.get(res, "result")
        );
      if (id && !item) return this.props.history.push(redirectPath);
    }
    this.setState({
      item,
    });
  };
  fetchItems = async () => {
    const { resource } = this.props;
    const items = await fetchApi(`${resource}`).then(res =>
      _.get(res, "result")
    );
    if (!items) return;
    this.setState({
      items,
    });
  };
  fetchOtherItems = async () => {
    const { otherResource } = this.props;
    const otherItems = await _.reduce(
      otherResource,
      async (result, resource) => {
        const items = await fetchApi(`${resource}`).then(res =>
          _.get(res, "result")
        );
        return {
          ...(await result),
          [resource]: items,
        };
      },
      Promise.resolve({})
    );
    this.setState({
      otherItems,
    });
  };
  fetchCategories = async () => {
    const { enableModule } = this.props;
    if (!_.get(enableModule, "categories")) return;
    const categories = await fetchApi(enableModule.categories).then(res =>
      _.get(res, "result")
    );
    this.setState({
      categories,
    });
  };
  autoFocusFirstInput = () => {
    setTimeout(() => {
      const firstElement = document.querySelector(".ant-form-item-children");
      if (_.get(firstElement, "childNodes")) firstElement.childNodes[0].focus();
    }, 200);
  };
  _create = async data => {
    const { resource, redirectPath, resourceType } = this.props;
    const created = await fetchApi(resource, "POST", JSON.stringify(data));
    if (_.get(created, "status") !== "success") {
      message.error(
        _.get(
          created,
          "error_message",
          resourceType === "page" ? "Save failed." : "Create failed."
        )
      );
      return;
    }
    message.success(
      resourceType === "page" ? "Save success." : "Create success."
    );
    if (resourceType !== "page") {
      this.props.history.push(redirectPath);
    }
  };
  _update = async data => {
    const id = _.toInteger(this.props.match.params.id);
    const { resource, redirectPath } = this.props;
    const updated = await fetchApi(
      `${resource}/${id}`,
      "PUT",
      JSON.stringify(data)
    );
    if (_.get(updated, "status") !== "success") {
      message.error(_.get(updated, "error_message", "Update failed."));
      return;
    }
    message.success("Update success.");
    this.props.history.push(redirectPath);
  };
  _delete = async () => {
    const id = _.toInteger(this.props.match.params.id);
    if (this.state.deleting) return;
    this.setState({ deleting: true });
    const { resource, redirectPath } = this.props;
    const deleted = await fetchApi(`${resource}/${id}`, "DELETE");
    if (_.get(deleted, "status") !== "success") {
      message.error(_.get(deleted, "error_message", "Delete failed."));
    } else {
      message.success("Delete success.");
      return this.props.history.push(redirectPath);
    }
    this.setState({ deleting: false });
  };
  submit = async data => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    const id = _.toInteger(this.props.match.params.id);
    if (id) {
      await this._update(data);
    } else {
      await this._create(data);
    }
    this.setState({ submitting: false });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { item } = this.state;
    const formItems = this.getFormItems();
    const { enableModule, languages } = this.props;
    const options = {
      scroll: {
        offsetTop: 96,
      },
    };

    this.props.form.validateFieldsAndScroll(options, (err, values) => {
      if (!err) {
        const data = convertDataToSubmit({
          values,
          formItems,
          languages,
        });
        if (_.get(enableModule, "tags") === true) {
          data.tags = JSON.stringify(data.tags);
        }
        console.log("data : ", data,"item ", item)
        if (item.seo != null){
          if (languages.active == "en") {
            if (item.seo.th != undefined) {
              data.seo.th = {}
              data.seo.th = item.seo.th
            }
          }
          if (languages.active == "th") {
            if (item.seo.en != undefined) {
              data.seo.en = {}
              data.seo.en = item.seo.en
            }
          }
        }
        
        this.submit(data);
      }
    });
  };
  renderPublishTime = () => {
    const { item } = this.state;
    const { form } = this.props;
    return (
      <Row gutter={8}>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "publish_start_time",
                label: "Publish Start Time",
                inputType: "date-picker",
                defaultValue: _.get(item, "publish_start_time"),
              },
            ]}
          />
        </Col>
        <Col lg={12}>
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "publish_end_time",
                label: "Publish End Time",
                inputType: "date-picker",
                defaultValue: _.get(item, "publish_end_time"),
              },
            ]}
          />
        </Col>
      </Row>
    );
  };
  renderPageHeader = () => {
    const { submitting, deleting, item } = this.state;
    const {
      title,
      redirectPath,
      permissionPrefix,
      auth,
      noEditIds,
      noDeleteIds,
      selfEdit,
      resourceType,
    } = this.props;
    const id = _.toInteger(this.props.match.params.id);
    const extra = [];
    if (id) {
      if (
        authHasPermission(_.get(auth, "permissions"), [
          `${permissionPrefix}.delete`,
        ]) &&
        !_.includes(noDeleteIds, id) &&
        resourceType !== "view"
      ) {
        extra.push(
          <Popconfirm
            key="delete"
            title="Are you sure delete this task?"
            onConfirm={this._delete}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" loading={deleting}>
              {deleting ? "Deleting..." : "Delete"}
            </Button>
          </Popconfirm>
        );
      }
    }
    let canEdit = false;
    if (selfEdit) canEdit = _.get(auth, "id") === _.get(item, selfEdit);
    if (
      ((authHasPermission(_.get(auth, "permissions"), [
        id ? `${permissionPrefix}.edit` : `${permissionPrefix}.add`,
      ]) &&
        !_.includes(noEditIds, id)) ||
        canEdit) &&
      resourceType !== "view"
    ) {
      extra.push(
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          loading={submitting}
        >
          {submitting ? "Saving..." : "Save"}
        </Button>
      );
    }
    const propsPageHeader = {
      title:
        resourceType === "page" ? title : `${id ? "Edit" : "New"} ${title}`,
      extra,
    };
    if (resourceType !== "page") {
      propsPageHeader.onBack = () => this.props.history.push(redirectPath);
    }
    return (
      <div className="page-header">
        <PageHeader {...propsPageHeader} />
      </div>
    );
  };
  renderMainContent = () => {
    const { item } = this.state;
    const formItems = this.getFormItems();
    const { form } = this.props;
    return (
      <div className="main-content">
        {((this.props.match.params.id && !_.isEmpty(item)) ||
          !this.props.match.params.id) && (
          <InputToFormItem form={form} formItems={formItems} />
        )}
      </div>
    );
  };
  getCategories = () => {
    const { categories } = this.state;
    const { t, enableModule } = this.props;
    if (!_.get(enableModule, "categories")) return;
    const resource = _.get(resources, enableModule.categories);
    const { formItems } = resource;
    const translateItems = _.map(categories, item =>
      translateData({ item, t, formItems })
    );
    const categoriesConvetedData = mapCategoriesLevel(translateItems);
    return categoriesConvetedData;
  };
  renderSeoForm = () => {
    const { item } = this.state;
    const { form, languages} = this.props;
    console.log(`seo.${languages.active}.meta:title`)
    return (
      <Collapse defaultActiveKey={["seo"]}>
        <Panel forceRender header="SEO" key="seo">
          <Tabs defaultActiveKey="seo">
            <TabPane forceRender tab="SEO" key="seo">
              <InputToFormItem
                form={form}
                formItems={[
                  {
                    field: `seo.${languages.active}.meta:title`,
                    label: "Title",
                    inputType: "text",
                    defaultValue: _.get(item, `seo.${languages.active}.meta:title`),
                    multiLanguage: true
                  },
                  {
                    field: `seo.${languages.active}.meta:description`,
                    label: "Meta description",
                    inputType: "textarea",
                    defaultValue: _.get(item, `seo.${languages.active}.meta:description`),
                    multiLanguage: true
                  },
                  {
                    field: `seo.${languages.active}.meta:keywords`,
                    label: "Meta keywords",
                    inputType: "textarea",
                    defaultValue: _.get(item, `seo.${languages.active}.meta:keywords`),
                    multiLanguage: true
                  },
                ]}
              />
            </TabPane>
            <TabPane forceRender tab="Social" key="social">
              <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel forceRender header="Facebook" key="facebook">
                  <InputToFormItem
                    form={form}
                    formItems={[
                      {
                        field: `seo.${languages.active}.og:image`,
                        label: "Facebook image",
                        inputType: "media",
                        inputOption: {
                          supportExtensions: ["jpg", "png", "svg"],
                        },
                        defaultValue: _.get(item, `seo.${languages.active}.og:image`),
                        multiLanguage: true
                      },
                      {
                        field: `seo.${languages.active}.og:title`,
                        label: "Facebook title",
                        inputType: "text",
                        defaultValue: _.get(item, `seo.${languages.active}.og:title`),
                        multiLanguage: true
                      },
                      {
                        field: `seo.${languages.active}.og:description`,
                        label: "Facebook description",
                        inputType: "textarea",
                        defaultValue: _.get(item, `seo.${languages.active}.og:description`),
                      },
                    ]}
                  />
                </Panel>
                <Panel forceRender header="Twitter" key="twitter">
                  <InputToFormItem
                    form={form}
                    formItems={[
                      {
                        field: `seo.${languages.active}.twitter:image`,
                        label: "Twitter image",
                        inputType: "media",
                        inputOption: {
                          supportExtensions: ["jpg", "png", "svg"],
                        },
                        defaultValue: _.get(item, `seo.${languages.active}.twitter:image`),
                        multiLanguage: true
                      },
                      {
                        field: `seo.${languages.active}.twitter:title`,
                        label: "Twitter title",
                        inputType: "text",
                        defaultValue: _.get(item, `seo.${languages.active}.twitter:title`),
                        multiLanguage: true
                      },
                      {
                        field: `seo.${languages.active}.twitter:description`,
                        label: "Twitter description",
                        inputType: "textarea",
                        defaultValue: _.get(item, `seo.${languages.active}.twitter:description`),
                        multiLanguage: true
                      },
                    ]}
                  />
                </Panel>
              </Collapse>
            </TabPane>
          </Tabs>
        </Panel>
      </Collapse>
    );
  };
  renderRightBar = () => {
    const { item } = this.state;
    const { form, enableModule } = this.props;
    const { getFieldsValue } = form;
    const formData = getFieldsValue();
    const publishedFormData = _.get(formData, "published");
    return (
      <div className="right-bar">
        {_.get(enableModule, "seo") === true && this.renderSeoForm()}
        {_.get(enableModule, "publish") === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "published",
                label: "Publish",
                inputType: "switch",
                defaultValue: _.get(item, "published", true),
              },
            ]}
          />
        )}
        {_.get(enableModule, "publish") === true &&
          _.get(enableModule, "publishTime") === true &&
          (!_.has(formData, "published") ||
            (_.has(formData, "published") && publishedFormData === true)) &&
          this.renderPublishTime()}
        {_.get(enableModule, "categories") && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "category_ids",
                label: "Categories",
                inputType: "tree-select",
                defaultValue: _.get(item, "category_ids", []),
                inputOption: {
                  multiple: true,
                  treeCheckable: true,
                  treeData: this.getCategories(),
                  showCheckedStrategy: SHOW_ALL,
                },
              },
            ]}
          />
        )}
        {_.get(enableModule, "tags") === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "tags",
                label: "Tags",
                inputType: "select",
                inputOption: {
                  mode: "tags",
                },
                defaultValue: _.isEmpty(_.get(item, "tags"))
                  ? []
                  : _.get(item, "tags", []),
              },
            ]}
          />
        )}
        {_.get(enableModule, "recommend") === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "recommended",
                label: "Recommended",
                inputType: "switch",
                defaultValue: _.get(item, "recommended", false),
              },
            ]}
          />
        )}
        {/* {_.get(enableModule, "sort") === true && (
          <InputToFormItem
            form={form}
            formItems={[
              {
                field: "sort",
                label: "Sort",
                inputType: "number",
                defaultValue: _.get(item, "sort", 0),
              },
            ]}
          />
        )} */}
      </div>
    );
  };
  checkHasModule = () => {
    const { enableModule } = this.props;
    return (
      enableModule.publish ||
      enableModule.publishTime ||
      enableModule.categories ||
      enableModule.tags ||
      enableModule.recommend ||
      enableModule.seo
    );
  };
  render() {
    const { addOtherItem } = this.state;
    const { languages } = this.props;
    const hasModule = this.checkHasModule();
    return (
      <div className="layout-save">
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <div className="page-header-container">
            <Affix>{this.renderPageHeader()}</Affix>
          </div>
          <Row>
            <Col md={hasModule ? 16 : 24}>
              <div className="main-content-container">
                {this.renderMainContent()}
              </div>
            </Col>
            {hasModule && (
              <Col md={8}>
                <div className="right-bar-container">
                  {this.renderRightBar()}
                  {/* <Affix offsetTop={73}>{this.renderRightBar()}</Affix> */}
                </div>
              </Col>
            )}
          </Row>
        </Form>
        <OtherForm
          {...addOtherItem}
          languages={languages}
          visible={!_.isEmpty(addOtherItem)}
          onClose={() => this.setState({ addOtherItem: null })}
          onCreated={async data => {
            await this.fetchOtherItems();
            // this.fetchItem();
            this.props.form.setFieldsValue({
              [addOtherItem.formItem.field]: _.get(
                data,
                addOtherItem.formItem.relation.mapResource.id
              ),
            });
            this.setState({ addOtherItem: null });
          }}
        />
      </div>
    );
  }
}

export default Form.create()(
  withRedux(mapStateToProps, actionToProps)(LayoutSave)
);
