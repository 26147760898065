import React from "react";
import { SketchPicker } from "react-color";

export default function ColorPicker({ color, onChange, ...props }) {
  return (
    <SketchPicker
      {...props}
      color={color || "rgba(0,0,0,1)"}
      onChangeComplete={color => {
        const rgba = `rgba(${color?.rgb?.r},${color?.rgb?.g},${color?.rgb?.b},${color?.rgb?.a})`;
        onChange(rgba);
      }}
    />
  );
}
