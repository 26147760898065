import React, { Component } from "react";
import {
  PageHeader,
  Button,
  Affix,
  Tooltip,
  Icon,
  Popconfirm,
  message,
  Tag,
  Table,
  Input,
  Select,
  Row,
  Col,
  Radio,
  DatePicker,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
// import Fuse from "fuse.js";
// import Highlighter from "react-highlight-words";
import { DragSortingTable } from "../../../components";
import { withRedux } from "../../../hoc";
import {
  fetchApi,
  authHasPermission,
  // JSONToCSVConvertor,
  stripHtml,
  translateData,
} from "../../../utils";
import modifyVars from "../../../config/modifyVars";
import "./style.less";

const Option = Select.Option;
// const Search = Input.Search;
const { RangePicker } = DatePicker;

const mapStateToProps = state => {
  return {
    t: _.get(state, "t"),
    languages: _.get(state, "languages"),
    auth: _.get(state, "auth.data"),
    medias: _.get(state, "medias.data", []),
  };
};

const actionToProps = {};

class LayoutList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayStatus: "all", // all, trash
      items: [],
      filterItems: [],
      trashItems: [],
      otherItems: [],
      searchValue: null,
      bulkAction: null,
      selectedRowKeys: [],
      filters: {},
      filterCreatedAt: [],
    };
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    this.init();
  }
  init = async () => {
    this.fetchTrashItems();
    await this.fetchOtherItems();
    this.fetchItems();
  };
  fetchTrashItems = async () => {
    const { resource } = this.props;
    const trashItems = await fetchApi(`trashes/table/${resource}`).then(res =>
      _.get(res, "result")
    );
    this.setState({
      trashItems,
    });
  };
  fetchOtherItems = async () => {
    const { otherResource } = this.props;
    const otherItems = await _.reduce(
      otherResource,
      async (result, resource) => {
        const items = await fetchApi(`${resource}`).then(res =>
          _.get(res, "result")
        );
        return {
          ...(await result),
          [resource]: items,
        };
      },
      Promise.resolve({})
    );
    this.setState({
      otherItems,
    });
  };
  fetchItems = async () => {
    this.setState({ loading: true });
    const { resource, enableModule } = this.props;
    const items = await fetchApi(`${resource}`)
      .then(res => _.get(res, "result"))
      .then(items => {
        if (_.get(enableModule, "sort")) {
          return _.orderBy(items, ["asc", "desc"]);
        }
        return items;
      });
    this.setState({
      items,
      loading: false,
    });
  };
  handleApplyBulkActions = () => {
    const { selectedRowKeys, bulkAction } = this.state;
    if (_.isEmpty(selectedRowKeys)) return;
    if (_.isEmpty(bulkAction)) return;
    if (bulkAction === "delete") this._deleteBulk();
    if (bulkAction === "restore") this._restoreBulk();
    if (bulkAction === "delete_permanently") this._deletePermanentlyBulk();
  };
  getBulkAction = () => {
    const { resourceType, enableModule } = this.props;
    const { displayStatus } = this.state;
    const options = [{ value: null, label: "Bulk Actions" }];
    if (displayStatus === "all") {
      if (_.get(enableModule, "delete") !== false) {
        options.push({ value: "delete", label: "Delete" });
      }
    }
    if (displayStatus === "trash") {
      options.push({ value: "restore", label: "Restore" });
      options.push({
        value: "delete_permanently",
        label: "Delete Permanently",
      });
    }
    if (_.size(options) > 1) {
      if (resourceType !== "view") {
        return (
          <React.Fragment>
            <Select
              placeholder="Bulk Actions"
              style={{ minWidth: 120 }}
              onChange={value => this.setState({ bulkAction: value })}
              value={this.state.bulkAction}
            >
              {_.map(options, (option, index) => {
                return (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
            </Select>{" "}
            <Button type="primary" onClick={this.handleApplyBulkActions}>
              Apply
            </Button>
          </React.Fragment>
        );
      }
    }
    return null;
  };
  getLeftActions = () => {
    // const { t, enableModule, formItems, resourceType } = this.props;
    // const { otherItems, displayStatus } = this.state;
    const { enableModule } = this.props;
    const { displayStatus } = this.state;
    const options = [{ value: null, label: "Bulk Actions" }];
    if (displayStatus === "all") {
      if (_.get(enableModule, "delete") !== false) {
        options.push({ value: "delete", label: "Delete" });
      }
    }
    if (displayStatus === "trash") {
      options.push({ value: "restore", label: "Restore" });
      options.push({
        value: "delete_permanently",
        label: "Delete Permanently",
      });
    }
    const actions = [];
    const bulkAction = this.getBulkAction();
    if (bulkAction) {
      actions.push(bulkAction);
    }
    // if (_.get(enableModule, 'publish')) {
    //   actions.push(
    //     <Select
    //       allowClear
    //       placeholder="Published"
    //       style={{ minWidth: 120 }}
    //       onChange={value => {
    //         const filters = this.state.filters;
    //         if (value === undefined) {
    //           delete filters.published;
    //         } else {
    //           filters.published = {
    //             value,
    //             condition_type: "eq"
    //           };
    //         }
    //         this.setState({ filters });
    //       }}
    //     >
    //       <Option value={true}>Published</Option>
    //       <Option value={false}>No Publish</Option>
    //     </Select>
    //   );
    // }
    // if (_.get(enableModule, 'recommend')) {
    //   actions.push(
    //     <Select
    //       allowClear
    //       placeholder="Recommended"
    //       style={{ minWidth: 150 }}
    //       onChange={value => {
    //         const filters = this.state.filters;
    //         if (value === undefined) {
    //           delete filters.recommended;
    //         } else {
    //           filters.recommended = {
    //             value,
    //             condition_type: "eq"
    //           };
    //         }
    //         this.setState({ filters });
    //       }}
    //     >
    //       <Option value={true}>Recommended</Option>
    //       <Option value={false}>No Recommend</Option>
    //     </Select>
    //   );
    // }
    // _.map(formItems, formItem => {
    //   const { filter, field, label, inputType, relation } = formItem;
    //   if (!filter) return;
    //   const resource = _.get(relation, "resource");
    //   const mapResourceId = _.get(relation, "mapResource.id");
    //   const mapResourceLabel = _.get(relation, "mapResource.label");
    //   const datas = _.get(otherItems, resource);
    //   if (inputType === "select") {
    //     actions.push(
    //       <Select
    //         key={field}
    //         showSearch
    //         allowClear
    //         filterOption={(input, option) =>
    //           _.lowerCase(option.props.children).indexOf(_.lowerCase(input)) >=
    //           0
    //         }
    //         placeholder={label}
    //         style={{ minWidth: 120 }}
    //         onChange={value => {
    //           const filters = this.state.filters;
    //           if (value === undefined) {
    //             delete filters[field];
    //           } else {
    //             filters[field] = {
    //               value,
    //               condition_type: "in"
    //             };
    //           }
    //           this.setState({ filters });
    //         }}
    //       >
    //         {_.map(datas, (data, index) => {
    //           return (
    //             <Option key={index} value={_.get(data, mapResourceId)}>
    //               {t(_.get(data, mapResourceLabel))}
    //             </Option>
    //           );
    //         })}
    //       </Select>
    //     );
    //   }
    // });
    return actions;
  };
  getRightActions = () => {
    return [];
    // const actions = [
    //   <Search
    //     autoFocus
    //     placeholder="Search"
    //     enterButton
    //     onSearch={searchValue => {
    //       if (this.state.searchValue !== searchValue) {
    //         this.setState({ searchValue });
    //       }
    //     }}
    //   />
    // ];
    // return actions;
  };
  renderActionsBar = () => {
    const leftActions = this.getLeftActions();
    const rightActions = this.getRightActions();
    return (
      <div className="actions-bar">
        <Row type="flex" justify="space-between">
          <Col>
            <div className="left">
              <ul>
                {_.map(leftActions, (leftAction, index) => {
                  return <li key={index}>{leftAction}</li>;
                })}
              </ul>
            </div>
          </Col>
          <Col>
            <div className="right">
              <ul>
                {_.map(rightActions, (rightAction, index) => {
                  return <li key={index}>{rightAction}</li>;
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  renderPageHeader = () => {
    const { displayStatus, items, trashItems } = this.state;
    const {
      title,
      path,
      permissionPrefix,
      auth,
      itemsLimit,
      resourceType,
      // exportFields,
      // formItems,
      // t,
      enableModule,
    } = this.props;
    // const filterItems = this.filterItems();
    // const translateItems = _.map(filterItems, item =>
    //   translateData({ item, t, formItems })
    // );
    const extra = [];
    let hasAddPermission = authHasPermission(_.get(auth, "permissions"), [
      `${permissionPrefix}.add`,
    ]);
    if (itemsLimit) {
      const itemsCount = _.size(items);
      if (itemsCount >= itemsLimit) {
        hasAddPermission = false;
      }
    }
    // extra.push(
    //   <Button
    //     key="download"
    //     type="dashed"
    //     icon="download"
    //     onClick={() => {
    //       let exportData = filterItems;
    //       if (exportFields) {
    //         exportData = _.map(filterItems, v => {
    //           const newData = {
    //             // ...v
    //             // id: _.get(v, "id"),
    //             // created_at: _.get(v, "created_at"),
    //             // updated_at: _.get(v, "updated_at")
    //           };
    //           for (let index = 0; index < exportFields.length; index++) {
    //             const exportField = exportFields[index];
    //             const { field, label } = exportField;
    //             const value = stripHtml(_.get(v, field));
    //             newData[label] = value;
    //           }
    //           return newData;
    //         });
    //       }
    //       JSONToCSVConvertor(exportData, title);
    //     }}
    //   >
    //     Export Excel
    //   </Button>
    // );

    if (_.get(enableModule, "add") !== false) {
      if (hasAddPermission && resourceType !== "view") {
        extra.push(
          <Link key="add" to={`${path}/save`}>
            <Button type="primary">Add New</Button>
          </Link>
        );
      }
    }

    let subTitle = null;
    if (_.get(enableModule, "delete") !== false) {
      if (resourceType !== "view") {
        subTitle = (
          <Radio.Group
            size="small"
            buttonStyle="solid"
            value={displayStatus}
            onChange={e => {
              const value = e.target.value;
              if (value === displayStatus) return;
              this.setState({ displayStatus: value, bulkAction: null });
            }}
          >
            <Radio.Button value="all">All ({_.size(items)})</Radio.Button>
            <Radio.Button value="trash">
              Trash ({_.size(trashItems)})
            </Radio.Button>
          </Radio.Group>
        );
      }
    }
    return (
      <div className="page-header">
        <PageHeader title={title} subTitle={subTitle} extra={extra}>
          {resourceType !== "view" && (
            <div className="actions-bar-container">
              {this.renderActionsBar()}
            </div>
          )}
        </PageHeader>
      </div>
    );
  };
  _update = async (id, data) => {
    const { resource } = this.props;
    const updateResult = await fetchApi(
      `${resource}/${id}`,
      "PUT",
      JSON.stringify(data)
    );
    return updateResult;
  };
  _delete = async id => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    const deleted = await fetchApi(`${resource}/${id}`, "DELETE");
    if (_.get(deleted, "status") !== "success") {
      message.error(_.get(deleted, "error_message", "Delete failed."));
    } else {
      await this.fetchItems();
      await this.fetchTrashItems();
      message.success("Delete success.");
    }
    this.setState({ loading: false });
  };
  _restore = async trash_id => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const restoreResult = await fetchApi(`trashes/restore/${trash_id}`);
    if (_.get(restoreResult, "status") !== "success") {
      message.error(_.get(restoreResult, "error_message", "Restore failed."));
    } else {
      await this.fetchItems();
      await this.fetchTrashItems();
      message.success("Restore success.");
    }
    this.setState({ loading: false });
  };
  _deletePermanently = async trash_id => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const deleteResult = await fetchApi(`trashes/${trash_id}`, "DELETE");
    if (_.get(deleteResult, "status") !== "success") {
      message.error(_.get(deleteResult, "error_message", "Delete failed."));
    } else {
      await this.fetchItems();
      await this.fetchTrashItems();
      message.success("Delete success.");
    }
    this.setState({ loading: false });
  };
  _deleteBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { resource } = this.props;
    const { selectedRowKeys } = this.state;
    const items = this.filterItems();
    await Promise.all(
      _.map(selectedRowKeys, async selectedRowKey => {
        const item = items[selectedRowKey - 1];
        const deleteResult = await fetchApi(`${resource}/${item.id}`, "DELETE");
        return deleteResult;
      })
    );
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success("Delete success.");
  };
  _restoreBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { selectedRowKeys, trashItems } = this.state;
    for (let index = 0; index < selectedRowKeys.length; index++) {
      const selectedRowKey = selectedRowKeys[index];
      const currentData = trashItems[selectedRowKey - 1];
      await fetchApi(`trashes/restore/${currentData.trash_id}`);
    }
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success("Restore success.");
  };
  _deletePermanentlyBulk = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    const { selectedRowKeys, trashItems } = this.state;
    for (let index = 0; index < selectedRowKeys.length; index++) {
      const selectedRowKey = selectedRowKeys[index];
      const currentData = trashItems[selectedRowKey - 1];
      await fetchApi(`trashes/${currentData.trash_id}`, "DELETE");
    }
    await this.fetchItems();
    await this.fetchTrashItems();
    this.setState({ selectedRowKeys: [], bulkAction: null, loading: false });
    message.success("Delete success.");
  };
  renderMediaByType = media => {
    if (!media) return null;
    const { name, fullpath, type } = media;
    if (type === "image") {
      return (
        <div className="type-image">
          <img src={fullpath} alt={name} />
        </div>
      );
    } else if (type === "application" || type === "video") {
      return (
        <div className="type-file">
          <div className="icon-file">
            <Icon type="file" theme="outlined" />
          </div>
        </div>
      );
    }
    return null;
  };
  getDataTable = () => {
    const { displayStatus, trashItems, items, otherItems } = this.state;
    const {
      formItems,
      path,
      enableModule,
      permissionPrefix,
      auth,
      noDeleteIds,
      resourceType,
      t,
      medias,
    } = this.props;

    const currentItems = displayStatus === "trash" ? trashItems : items;
    // const items = this.filterItems();

    const getColumnProps = formItem => {
      const { field, label, inputType, relation } = formItem;

      let columnProps = {
        title: label,
        dataIndex: field,
        key: field,
      };

      if (relation) {
        const resource = _.get(relation, "resource");
        const mapResourceId = _.get(relation, "mapResource.id");
        const mapResourceLabel = _.get(relation, "mapResource.label");
        const datas = _.get(otherItems, resource);
        if (inputType === "select") {
          columnProps = {
            ...columnProps,
            filters: _.map(datas, data => {
              return {
                text: t(data[mapResourceLabel]),
                value: data[mapResourceId],
              };
            }),
            render: (text, record, index) => {
              const data = _.find(datas, { [mapResourceId]: text });
              return t(_.get(data, mapResourceLabel));
            },
            onFilter: (value, record) => _.get(record, field) === value,
          };
        }
      } else {
        if (inputType === "text") {
          columnProps = {
            ...columnProps,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={node => {
                    this.searchInput = node;
                  }}
                  placeholder={`Search ${label}`}
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => confirm()}
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => clearFilters()}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </div>
            ),
            filterIcon: filtered => (
              <Icon
                type="search"
                style={{
                  color: filtered ? modifyVars["@primary-color"] : undefined,
                }}
              />
            ),
            onFilter: (value, record) => {
              return _.includes(
                _.lowerCase(_.toString(_.get(record, field))),
                _.lowerCase(value)
              );
            },
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => this.searchInput.select());
              }
            },
            sorter: (a, b) => _.get(a, field).localeCompare(_.get(b, field)),
            render: text => stripHtml(text),
          };
        } else if (inputType === "boolean") {
          columnProps = {
            ...columnProps,
            filters: [
              {
                text: "true",
                value: true,
              },
              {
                text: "false",
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, record) => _.get(record, field) === value,
            sorter: (a, b) => _.get(a, field) - _.get(b, field),
            render: text => _.toString(text),
          };
        } else if (inputType === "number") {
          columnProps = {
            ...columnProps,
            sorter: (a, b) => _.get(a, field) - _.get(b, field),
          };
        } else if (inputType === "range-picker") {
          columnProps = {
            ...columnProps,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              return (
                <div style={{ padding: 8 }}>
                  <RangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    value={selectedKeys}
                    onChange={dates => {
                      setSelectedKeys(dates);
                      this.setState({ filterCreatedAt: dates });
                      _.isEmpty(dates) ? clearFilters() : confirm();
                    }}
                    style={{ width: 248, marginBottom: 8, display: "block" }}
                  />
                </div>
              );
            },
            filterIcon: filtered => {
              return (
                <Icon
                  type="calendar"
                  style={{
                    color: filtered ? modifyVars["@primary-color"] : undefined,
                  }}
                />
              );
            },
            onFilter: (value, record) => {
              return moment(_.get(record, field)).isBetween(
                this.state.filterCreatedAt[0],
                this.state.filterCreatedAt[1],
                "day",
                []
              );
            },
            sorter: (a, b) => _.get(a, field).localeCompare(_.get(b, field)),
          };
        } else if (inputType === "media") {
          columnProps = {
            ...columnProps,
            render: text => {
              const media = _.find(medias, { id: text });
              if (!media) return null;
              return this.renderMediaByType(media);
            },
          };
        } else if (inputType === "switch") {
          columnProps = {
            ...columnProps,
            render: text => {
              console.log("text", text);
              if (!text)
                return (
                  <Icon
                    type="close"
                    style={{
                      color: modifyVars["@error-color"],
                    }}
                  />
                );
              return (
                <Icon
                  type="check"
                  style={{
                    color: modifyVars["@success-color"],
                  }}
                />
              );
            },
          };
        } else {
          columnProps = {
            ...columnProps,
            sorter: (a, b) => _.get(a, field).localeCompare(_.get(b, field)),
          };
        }
      }

      return columnProps;
    };

    const getColumn = () => {
      const columns = [];

      // columns.push({
      //   title: "#",
      //   dataIndex: "key",
      //   key: "key",
      //   sorter: (a, b) => _.toInteger(a.key) - _.toInteger(b.key),
      //   render: text => text,
      // });

      columns.push({
        title: "#",
        key: "index",
        sorter: (a, b) => _.toInteger(a.key) - _.toInteger(b.key),
        render: (text, record, index) => index + 1,
      });

      if (!_.isEmpty(formItems)) {
        for (let index = 0; index < formItems.length; index++) {
          const formItem = formItems[index];
          const { tableDisplay } = formItem;
          if (tableDisplay === true) {
            columns.push({
              ...getColumnProps(formItem),
            });
          }
        }
      }

      if (_.get(enableModule, "publish")) {
        columns.push({
          title: "Published",
          dataIndex: "published",
          key: "published",
          sorter: (a, b) => b.published - a.published,
          render: text => (
            <Tag color={text ? "green" : "red"}>
              {text ? "Published" : "No Publish"}
            </Tag>
          ),
        });
      }

      if (_.get(enableModule, "recommend")) {
        columns.push({
          title: "Recommended",
          dataIndex: "recommended",
          key: "recommended",
          sorter: (a, b) => b.recommended - a.recommended,
          render: text => (
            <Tag color={text ? "green" : "red"}>
              {text ? "Recommended" : "No Recommend"}
            </Tag>
          ),
        });
      }

      if (_.get(enableModule, "showCreatedAt")) {
        columns.push({
          ...getColumnProps({
            field: "created_at",
            label: "Create Date",
            inputType: "range-picker",
          }),
        });
      }

      if (_.get(enableModule, "showUpdatedAt")) {
        columns.push({
          ...getColumnProps({
            field: "updated_at",
            label: "Updated Date",
            inputType: "range-picker",
          }),
        });
      }

      const getActionBtns = id => {
        const actionBtns = [];
        if (resourceType === "view") {
          actionBtns.push(
            <Link to={`${path}/save/${id}`}>
              <Tooltip title="View">
                <Icon type="eye" />
              </Tooltip>
            </Link>
          );
        } else {
          const trashItem = _.find(trashItems, { id });
          const trash_id = _.get(trashItem, "trash_id");
          const hasDeletePermission = authHasPermission(
            _.get(auth, "permissions"),
            [`${permissionPrefix}.delete`]
          );
          const noDeleteThisId = !_.includes(noDeleteIds, id);
          if (displayStatus === "all") {
            actionBtns.push(
              <Link to={`${path}/save/${id}`}>
                <Tooltip title="Edit">
                  <Icon type="edit" />
                </Tooltip>
              </Link>
            );
            if (hasDeletePermission && noDeleteThisId) {
              actionBtns.push(
                <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={() => this._delete(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete">
                    <Icon type="delete" />
                  </Tooltip>
                </Popconfirm>
              );
            }
          } else if (displayStatus === "trash") {
            if (hasDeletePermission) {
              actionBtns.push(
                <Popconfirm
                  title="Are you sure restore this task?"
                  onConfirm={() => this._restore(trash_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Restore">
                    <Icon type="rollback" />
                  </Tooltip>
                </Popconfirm>
              );
              actionBtns.push(
                <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={() => this._deletePermanently(trash_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete Permanently">
                    <Icon type="delete" />
                  </Tooltip>
                </Popconfirm>
              );
            }
          }
        }
        return actionBtns;
      };

      columns.push({
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: id => {
          const actionBtns = getActionBtns(id);
          return (
            <ul className="actions-btn">
              {_.map(actionBtns, (actionBtn, index) => {
                return <li key={index}>{actionBtn}</li>;
              })}
            </ul>
          );
        },
      });

      return columns;
    };

    const getDataSource = () => {
      const { t, formItems } = this.props;
      const translateItems = _.map(currentItems, item =>
        translateData({ item, formItems, t })
      );
      const dataSource = _.map(translateItems, (value, index) => {
        return {
          ...value,
          key: index + 1,
        };
      });
      return dataSource;
    };

    const columns = getColumn();
    const dataSource = getDataSource();

    return { columns, dataSource };
  };
  renderMainContent = () => {
    const { enableModule } = this.props;
    const { loading, selectedRowKeys } = this.state;
    const { columns, dataSource } = this.getDataTable();
    const tableProps = {
      ref: this.tableRef,
      columns,
      dataSource,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        showTotal: total => `Total ${total} items`,
        showQuickJumper: true,
      },
      onMove: async value => {
        // const { data, dragIndex, hoverIndex } = value;
        // let startIndex = dragIndex;
        // let endIndex = hoverIndex;
        // if (dragIndex > hoverIndex) {
        //   startIndex = hoverIndex;
        //   endIndex = dragIndex;
        // }
        // for (let index = startIndex; index <= endIndex; index++) {
        //   const element = data[index];
        //   await this._update(element.id, { sort: index });
        // }
        if (this.state.loading) return;
        const { data } = value;
        this.setState({ loading: true });
        const updateResults = await Promise.all(
          _.map(data, async (d, index) => {
            const result = await this._update(d.id, { sort: index + 1 });
            return result;
          })
        );
        this.setState({ loading: false });
        console.log("updateResults", updateResults);
        message.success("Sort success.");
      },
      size: "small",
      loading,
      onChange: (pagination, filters, sorter, extra) => {
        // console.log("pagination", pagination);
        // console.log("filters", filters);
        // console.log("sorter", sorter);
        // console.log("extra", extra);
        this.setState({ filterItems: _.get(extra, "currentDataSource") });
      },
      // scroll: { y: window.innerHeight / 1.5 }
    };
    const bulkAction = this.getBulkAction();
    if (bulkAction) {
      tableProps.rowSelection = {
        selectedRowKeys,
        onChange: selectedRowKeys => this.setState({ selectedRowKeys }),
      };
    }
    return (
      <div className="main-content">
        {_.get(enableModule, "sort") ? (
          <DragSortingTable {...tableProps} />
        ) : (
          <Table {...tableProps} />
        )}
      </div>
    );
  };
  filterItems = () => {
    const { items, filterItems, trashItems, displayStatus } = this.state;
    const newItems = !_.isEmpty(filterItems)
      ? filterItems
      : displayStatus === "trash"
      ? trashItems
      : items;
    return newItems;
    // const {
    //   items,
    //   trashItems,
    //   searchValue,
    //   filters,
    //   displayStatus
    // } = this.state;
    // const { formItems } = this.props;
    // const filterKey = _.map(
    //   _.filter(formItems, "search"),
    //   formItem => formItem.field
    // );
    // const languages = this.props.languages.data;
    // let newItems = items;
    // if (displayStatus === "trash") {
    //   newItems = trashItems;
    // }
    // if (!_.isEmpty(filters)) {
    //   _.map(filters, (filter, field) => {
    //     const value = _.get(filter, "value");
    //     const conditionType = _.get(filter, "condition_type", "eq");
    //     // eq       => Equals.
    //     // finset   =>	A value within a set of values
    //     // from     =>	The beginning of a range. Must be used with to
    //     // gt       =>	Greater than
    //     // gteq     =>	Greater than or equal
    //     // in       =>	In. The value can contain a comma-separated list of values.
    //     // like     =>	Like. The value can contain the SQL wildcard characters when like is specified.
    //     // lt       =>	Less than
    //     // lteq     =>	Less than or equal
    //     // moreq    =>	More or equal
    //     // neq      =>	Not equal
    //     // nfinset  =>	A value that is not within a set of values
    //     // nin      =>	Not in. The value can contain a comma-separated list of values.
    //     // notnull  =>	Not null
    //     // null     =>	Null
    //     // to       =>	The end of a range. Must be used with from
    //     if (conditionType === "eq") {
    //       newItems = _.filter(newItems, { [field]: value });
    //     } else if (conditionType === "in") {
    //       newItems = _.filter(newItems, newItem => {
    //         return _.includes(newItem[field], value);
    //       });
    //     }
    //   });
    // }
    // if (searchValue) {
    //   const keys = [...(filterKey || [])];
    //   _.map(filterKey, value => {
    //     _.map(languages, language => {
    //       keys.push(`${value}.${language.code}`);
    //     });
    //   });
    //   const options = {
    //     shouldSort: true,
    //     threshold: 0.4,
    //     location: 0,
    //     distance: 100,
    //     maxPatternLength: 32,
    //     minMatchCharLength: 1,
    //     keys
    //   };
    //   const fuse = new Fuse(newItems, options);
    //   newItems = fuse.search(searchValue);
    // }
    // return newItems;
  };
  render() {
    return (
      <div className="layout-list">
        <div className="page-header-container">
          <Affix>{this.renderPageHeader()}</Affix>
        </div>
        <div className="main-content-container">{this.renderMainContent()}</div>
      </div>
    );
  }
}

export default withRedux(mapStateToProps, actionToProps)(LayoutList);
