import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import _ from "lodash";
import configureStore from "./store/configureStore";
import routes from "./config/routes";
import { PrivateRoute } from "./components";
import { withRedux } from "./hoc";
import { initApp } from "./actions";
import { Login } from "./pages";
import Layout from "./config/Layout";
import { getMedias } from "./actions/medias";
import "./assets/css/index.less";

const store = configureStore();

const mapStateToProps = state => {
  return {
    isLogin: _.get(state, "auth.isLogin"),
    authChecked: _.get(state, "auth.checked"),
  };
};

const actionToProps = { initApp, getMedias };

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.props.actions.initApp();
  }

  render() {
    const { isLogin, authChecked } = this.props;
    if (!authChecked) return <div>Loading...</div>;
    if (!isLogin) return <Login />;
    return (
      <Router>
        <Route
          render={props => {
            const { location } = props;
            return (
              <Switch location={location}>
                {_.map(routes, (route, index) => {
                  const { privateRoute } = route;
                  const R = privateRoute ? PrivateRoute : Route;
                  return (
                    <R
                      key={index}
                      {...route}
                      component={props => {
                        return <Layout {...route} {...props} />;
                      }}
                    />
                  );
                })}
              </Switch>
            );
          }}
        />
      </Router>
    );
  }
}

const AppProvider = withRedux(mapStateToProps, actionToProps)(AppRouter);

function App() {
  return (
    <Provider store={store} key="provider">
      <AppProvider />
    </Provider>
  );
}

export default App;
