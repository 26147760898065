import React, { PureComponent } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock";
import ImageInline from "@ckeditor/ckeditor5-image/src/imageinline";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

class TextEditor extends PureComponent {
  render() {
    const { value, onChange } = this.props;

    const config = {
      plugins: [
        Essentials,
        UploadAdapter,
        Autoformat,
        Bold,
        Code,
        Italic,
        Strikethrough,
        Subscript,
        Superscript,
        Underline,
        BlockQuote,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        ImageInsert,
        ImageResize,
        ImageBlock,
        ImageInline,
        LinkImage,
        Link,
        List,
        TodoList,
        Paragraph,
        Alignment,
        Font,
        Table,
        TableCellProperties,
        TableProperties,
        TableToolbar,
        TableCaption,
        Indent,
        IndentBlock,
        CodeBlock,
        Highlight,
        HorizontalLine,
        HtmlEmbed,
        GeneralHtmlSupport,
        SourceEditing,
        MediaEmbed,
        PasteFromOffice,
        RemoveFormat,
        SimpleUploadAdapter,
      ],
      toolbar: {
        items: [
          "|",
          "heading",
          "|",
          "fontfamily",
          "fontsize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "strikethrough",
          "underline",
          "subscript",
          "superscript",
          "highlight",
          "|",
          "link",
          "mediaEmbed",
          "insertImage",
          "|",
          "alignment",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "|",
          "outdent",
          "indent",
          "|",
          "insertTable",
          "|",
          "htmlEmbed",
          "|",
          "horizontalLine",
          "|",
          "code",
          "codeBlock",
          "blockQuote",
          "|",
          "removeFormat",
          "|",
          "undo",
          "redo",
          "|",
          "sourceEditing",
        ],
        shouldNotGroupWhenFull: true,
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: inputHtml => {
          // Strip unsafe elements and attributes, e.g.:
          // the `<script>` elements and `on*` attributes.
          // const outputHtml = sanitize(inputHtml);
          const outputHtml = inputHtml;

          return {
            html: outputHtml,
            // true or false depending on whether the sanitizer stripped anything.
            hasChanged: true,
          };
        },
      },
      simpleUpload: {
        uploadUrl: `${process.env.REACT_APP_SERVICE}/${process.env.REACT_APP_API}/medias/ckeditor`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      },
      image: {
        toolbar: [
          {
            name: "imageStyle:alignBlock",
            items: [
              "imageStyle:alignBlockLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignBlockRight",
            ],
            defaultItem: "imageStyle:alignCenter",
          },
          {
            name: "imageStyle:align",
            items: ["imageStyle:alignLeft", "imageStyle:alignRight"],
            defaultItem: "imageStyle:alignLeft",
          },
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
    };

    return (
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={config}
        // onReady={editor => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log("Editor is ready to use!", editor);
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
      />
    );
  }
}

export default TextEditor;
