import _ from "lodash";
import * as ActionType from "../actions/actionType";
import { getSettingsApi } from "../services/settings";
import queryString from "../utils/queryString";

const getSettings = () => {
  return (dispatch, ownProps) => {
    return dispatchSettings(dispatch, ownProps);
  };
};

const dispatchSettings = (dispatch, ownProps) => {
  return getSettingsApi().then(data => {
    dispatch({
      type: ActionType.RECEIVE_SETTINGS,
      data
    });
    const defaultLanguage = _.get(data, "defaultLanguage");
    const language = _.get(queryString.parse(window.location.search), "lang", defaultLanguage);
    if (language !== _.get(ownProps, "languages.active")) {
      dispatch({
        type: ActionType.SWITCH_LANGUAGE,
        language
      });
    }
    return data;
  });
};

export { getSettings, dispatchSettings };
